import React, { FC, ChangeEvent, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Stack } from '@mui/material';
import { InputAdornment, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import logo_vrt from './../../images/logo_vrt.png';
import SwipeableViews from 'react-swipeable-views';
import { Stepper, Step, StepLabel } from '@mui/material';
import DateInput from '../EABCharts/DateInput';
import userService from '../../services/UserServices';
import { UserPostFields } from '../../redux/types/userTypes';
import { useDispatch } from 'react-redux';
import { userActions } from '../../redux/slices/userSlice';

const businessSteps = ['Info. Personal', 'Datos Medicos'];

const CompleteRegistration: FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const rut = queryParams.get('rut');
  const phone = queryParams.get('phone');
  const [user, setUser] = useState<UserPostFields | null>();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const [birthday, setBirthday] = useState<string | null>(null);
  const [sex, setSex] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [submitError, setSubmitError] = useState('');

  const loadUser = async () => {
    if (rut && phone) {
      const response = await userService.isRegistered(rut);
      if (response.user !== null) {
        setUser(response.user);
      }
    }
  };

  useEffect(() => {
    if (rut && phone) {
      loadUser();
    }
  }, []);

  const changePassword = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(event.target.value);
    if (confirmation !== event.target.value) {
      setPasswordError('Las contraseñas no coinciden');
    } else {
      setPasswordError('');
    }
  };
  const changeConfirmation = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setConfirmation(event.target.value);
    if (password !== event.target.value) {
      setPasswordError('Las contraseñas no coinciden');
    } else {
      setPasswordError('');
    }
  };
  const changeHeight = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      setHeight(event.target.value);
    }
  };
  const changeWeight = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      setWeight(event.target.value);
    }
  };
  const changeBirthday = (event: string | null) => {
    setBirthday(event);
  };
  const changeSex = (event: SelectChangeEvent) => {
    setSex(event.target.value as string);
  };
  const register = async () => {
    //ok
    const response = await userService.completeRegistration({
      rut: user?.rut || '',
      phone: user?.phone || '',
      password: password,
      weight: parseInt(weight),
      height: parseInt(height),
      sex: sex,
      birthday: birthday || '',
    });
    if (response.user) {
      console.log('logging in!!');
      const { user } = response;
      user.token = response.token;
      user.redirect = 'nordic';
      user.mode = 'active-in';
      dispatch(userActions.login(user));
    } else {
      setSubmitError(response.error);
    }
  };
  const login = () => {
    navigate('/login');
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <Box sx={{ maxWidth: '100vw', minWidth: '310px', display: 'inline-block', padding: '5%' }}>
      <Box sx={{ width: '100%', marginBottom: '12px' }}>
        {activeStep > 0 && (
          <Stepper activeStep={activeStep - 1} alternativeLabel>
            {businessSteps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
      </Box>
      <SwipeableViews disabled index={activeStep}>
        <Box>
          <Box sx={{ width: '100%', marginY: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={logo_vrt} height={'100px'} />
          </Box>
          <Box sx={{ width: '100%', marginY: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h3 className="App-logo-text">
              Hola {user?.first_name}, completa tu registro para acceder a la app desde tu celular!
            </h3>
          </Box>
          <Button
            onClick={handleNext}
            size="large"
            sx={{
              width: '100%',
              marginY: '2%',
              fontWeight: 'bold',
              borderRadius: '50px',
              backgroundImage: 'linear-gradient(to bottom right,#0068ff, #7be7c6)',
            }}
            variant="contained">
            EMPEZEMOS
          </Button>
          <Button onClick={login} size="large" sx={{ width: '100%', marginY: '2%', fontWeight: 'bold' }} variant="text">
            Ya tienes cuenta? Inicia sesion
          </Button>
        </Box>
        <Box>
          <Button onClick={handleBack} size="small" sx={{ marginY: '2%', fontWeight: 'bold' }} variant="text">
            {'← Atras'}
          </Button>
          <TextField
            value={password}
            onChange={changePassword}
            sx={{ width: '100%', marginY: '2%' }}
            label="Contraseña"
            type="password"
          />
          <TextField
            value={confirmation}
            onChange={changeConfirmation}
            sx={{ width: '100%', marginY: '2%' }}
            label="Confirma tu contraseña"
            type="password"
            error={passwordError !== ''}
            helperText={passwordError}
          />
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={12}>
            <Button
              disabled={password === '' || confirmation !== password || passwordError !== ''}
              onClick={handleNext}
              size="large"
              sx={{ marginY: '2%', fontWeight: 'bold', borderRadius: '50px' }}
              variant="contained">
              {'Siguiente →'}
            </Button>
          </Stack>
        </Box>
        <Box>
          <Button onClick={handleBack} size="small" sx={{ marginY: '2%', fontWeight: 'bold' }} variant="text">
            {'← Atras'}
          </Button>
          <TextField
            value={weight}
            onChange={changeWeight}
            sx={{ width: '100%', marginY: '2%' }}
            label="Peso"
            variant="outlined"
            inputProps={{ inputMode: 'numeric' }}
            InputProps={{
              endAdornment: <InputAdornment position="start">kg</InputAdornment>,
            }}
          />
          <TextField
            value={height}
            onChange={changeHeight}
            sx={{ width: '100%', marginY: '2%' }}
            label="Altura"
            variant="outlined"
            inputProps={{ inputMode: 'numeric' }}
            InputProps={{
              endAdornment: <InputAdornment position="start">cm</InputAdornment>,
            }}
          />
          <p>Fecha de nacimiento</p>
          <DateInput onChange={changeBirthday} />
          <FormControl sx={{ width: '100%', marginY: '2%' }}>
            <InputLabel id="Sselect-label">Sexo</InputLabel>
            <Select
              value={sex}
              onChange={changeSex}
              labelId="Sselect-label"
              id="simple-select"
              label="Sexo"
              variant="outlined"
              sx={{ width: '100%' }}>
              <MenuItem value="F">Mujer</MenuItem>
              <MenuItem value="M">Hombre</MenuItem>
              <MenuItem value="-">Prefiero no decir</MenuItem>
            </Select>
          </FormControl>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={12}>
            {submitError !== '' && <p style={{ color: 'red' }}>{submitError}</p>}
            <Button
              onClick={register}
              size="large"
              sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '50px' }}
              disabled={
                password === '' ||
                confirmation !== password ||
                birthday === null ||
                passwordError !== '' ||
                sex === '' ||
                height === '' ||
                weight === ''
              }
              variant="contained">
              EMPEZAR
            </Button>
          </Stack>
        </Box>
      </SwipeableViews>
    </Box>
  );
};

export default CompleteRegistration;
