import React, { FC, useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import Terms from '../components/Ranking/Terms';
import Podium from '../components/Ranking/Podium';
import PointsCard from '../components/Ranking/PointsCard';
import { connect, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { bindActionCreators } from 'redux';
import BadgeCard from '../components/Ranking/BadgeCard';
import { BadgeDirectory } from '../components/Ranking/BadgeDirectory';
import languages from '../language/languages';
import * as rankingInteractors from '../redux/interactors/rankingInteractors';

interface Props {
  getRankingInteractor: typeof rankingInteractors.getRankingInteractor;
}

const Ranking: FC<Props> = (props: Props) => {
  const badges = BadgeDirectory;
  const [open, setOpen] = useState(false);
  const token = useSelector((state: RootState) => state.user.token);
  const ranking = useSelector((state: RootState) => state.ranking);
  const { selected } = useSelector((state: RootState) => state.language);
  useEffect(() => {
    props.getRankingInteractor(token);
  }, []);
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100%',
        minHeight: '100vh',
        paddingTop: '5vh',
        paddingBottom: '15vh',
        backgroundColor: '#333',
        color: 'white',
      }}>
      {ranking.ranking && <Podium />}
      {/* <Progress value={totalPoints} /> */}
      <PointsCard />
      <Box sx={{ padding: 2 }}>
        <h2 className="App-logo-text" style={{ padding: 0 }}>
          {languages[selected].ranking.badges.title}
        </h2>
        <h4 className="App-logo-text" style={{ padding: 0 }}>
          {languages[selected].ranking.badges.subtitle}
        </h4>
      </Box>
      {badges.map((badge, index) => {
        return (
          <BadgeCard
            key={index}
            title={badge.title}
            description={badge.description}
            imgSrc={badge.imgSrcMd}
            earned={ranking.badges?.includes(badge.id) || false}
            congratulations={badge.congratulations}
            background={badge.background}
          />
        );
      })}
      <Button
        sx={{ width: '90%', borderRadius: '50px', fontWeight: 'bold', boxShadow: 'none' }}
        variant="contained"
        size="large"
        onClick={() => {
          setOpen(true);
        }}>
        {languages[selected].ranking.terms.cta}
      </Button>
      <Terms
        open={open}
        onChange={() => {
          setOpen(false);
        }}
      />
    </Box>
  );
};

const mapDispatchToProps = (dispatch: any): Props => ({
  ...bindActionCreators(
    {
      ...rankingInteractors,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(Ranking);
