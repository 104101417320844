import React, { FC, ChangeEvent, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import userService from '../../services/UserServices';
import Terms from '../Terms/Terms';
import { Term } from '../../redux/types/terms';
import { userActions } from '../../redux/slices/userSlice';
import termsService from '../../services/TermsServices';
import { Box, TextField, Button, Typography, Stack } from '@mui/material';
import { InputAdornment, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import logo_vrt from './../../images/logo_vrt.png';
import { dgv, formatValue } from './AddRUT';
import SwipeableViews from 'react-swipeable-views';
import { Stepper, Step, StepLabel } from '@mui/material';
import DateInput from '../EABCharts/DateInput';

const steps = ['Info. Personal', 'Datos Medicos', 'Terminos de uso', 'Activar'];
const businessSteps = ['Info. Personal', 'Datos Medicos', 'Terminos de uso'];

const UserRegister: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [RUT, setRUT] = useState('');
  const [RUTError, setRUTError] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const [birthday, setBirthday] = useState<string | null>(null);
  const [sex, setSex] = useState('');
  const [diabetes, setDiabetes] = useState(false);
  const [thrombosis, setThrombosis] = useState(false);
  const [heartDisease, setHeartDisease] = useState(false);
  const [otherDiseases, setOtherDiseases] = useState('');
  const [activationCode, setActivationCode] = useState('');
  const [submitError, setSubmitError] = useState('');
  const [terms, setTerms] = useState<Term | null>(null);
  const [accept, setAccept] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [phone, setPhone] = useState('569');
  const [phoneError, setPhoneError] = useState('');
  const { token, default_department } = useSelector((state: RootState) => state.business);
  useEffect(() => {
    termsService.getTerms().then((response) => setTerms(response));
    if (default_department !== null && default_department !== undefined) {
      setActivationCode(default_department);
    }
  }, []);
  const changeRUT = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    const formattedValue = formatValue(value);
    const checkDigit = dgv(+formattedValue.slice(0, -2));
    let validRut = '';
    if (checkDigit.toString() !== formattedValue.charAt(formattedValue.length - 1)) {
      validRut = 'El RUT entregado no es válido';
    }
    setRUT(formattedValue);
    setRUTError(validRut);
    const response = await userService.isRegistered(formattedValue);
    if (response.user !== null) {
      setRUTError('El RUT ingresado ya está registrado');
    }
  };
  const changeName = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setName(event.target.value);
  };
  const changeLastName = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLastName(event.target.value);
  };
  const changePassword = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(event.target.value);
    if (confirmation !== event.target.value) {
      setPasswordError('Las contraseñas no coinciden');
    } else {
      setPasswordError('');
    }
  };
  const changeConfirmation = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setConfirmation(event.target.value);
    if (password !== event.target.value) {
      setPasswordError('Las contraseñas no coinciden');
    } else {
      setPasswordError('');
    }
  };
  const changeHeight = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      setHeight(event.target.value);
    }
  };
  const changeWeight = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      setWeight(event.target.value);
    }
  };
  const changeBirthday = (event: string | null) => {
    setBirthday(event);
  };
  const changeOtherDiseases = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setOtherDiseases(event.target.value);
  };
  const changeActivationCode = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setActivationCode(event.target.value);
  };
  const changeSex = (event: SelectChangeEvent) => {
    setSex(event.target.value as string);
  };
  const register = async () => {
    const response = await userService.createUser(
      {
        email: '',
        rut: RUT,
        password,
        first_name: name,
        last_name: lastName,
        height: +height,
        weight: +weight,
        birthday: birthday ? birthday : '',
        sex,
        diabetes,
        heart_diseases: heartDisease,
        other_diseases: otherDiseases,
        thrombosis,
        business_id: parseInt(
          activationCode.includes('-') === true
            ? activationCode.split('-')[0].substring(1)
            : activationCode.split(' ')[0].substring(1),
          16,
        ),
        phone,
      },
      activationCode,
      terms?.id || NaN,
    );
    if (response.user) {
      const { user } = response;
      user.token = response.token;
      if (token !== null && token !== undefined) {
        user.mode = 'active-in';
        user.redirect = 'nordic';
        dispatch(userActions.login(user));
      } else {
        dispatch(userActions.login(user));
      }
    } else {
      setSubmitError(response.error);
    }
  };
  const changePhone = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const regex = /^[0-9\b]+$/;
    if (
      event.target.value === '' ||
      (regex.test(event.target.value) && event.target.value.slice(0, 3) === '569' && event.target.value.length <= 11)
    ) {
      setPhone(event.target.value);
      const response = await userService.phoneIsRegistered(event.target.value);
      if (event.target.value.length === 11 && response.user === null) {
        setPhoneError('');
      } else if (event.target.value.length === 11 && response.user !== null) {
        setPhoneError('El telefono ingresado ya está registrado');
      } else {
        setPhoneError('Debe tener 11 digitos');
      }
    } else if (event.target.value.slice(0, 3) !== '569') {
      setPhoneError('Codigo de area invalido');
    } else if (event.target.value.length <= 10) {
      setPhoneError('Debe tener 11 digitos');
    } else if (event.target.value.length !== 12) {
      setPhoneError('Error al ingresar el número');
    }
  };
  const login = () => {
    navigate('/login');
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <Box sx={{ maxWidth: '100vw', minWidth: '310px', display: 'inline-block', padding: '5%' }}>
      <Box sx={{ width: '100%', marginBottom: '12px' }}>
        {activeStep > 0 && (
          <Stepper activeStep={activeStep - 1} alternativeLabel>
            {(default_department !== undefined ? businessSteps : steps).map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
      </Box>
      <SwipeableViews disabled index={activeStep}>
        <Box>
          <Box sx={{ width: '100%', marginY: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={logo_vrt} height={'100px'} />
          </Box>
          <Box sx={{ width: '100%', marginY: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h3 className="App-logo-text">Registrate para acceder a makana siempre que lo necesites!</h3>
          </Box>
          <Button
            onClick={handleNext}
            size="large"
            sx={{
              width: '100%',
              marginY: '2%',
              fontWeight: 'bold',
              borderRadius: '50px',
              backgroundImage: 'linear-gradient(to bottom right,#0068ff, #7be7c6)',
            }}
            variant="contained">
            EMPEZAR MI REGISTRO
          </Button>
          <Button onClick={login} size="large" sx={{ width: '100%', marginY: '2%', fontWeight: 'bold' }} variant="text">
            Ya tienes cuenta? Inicia sesion
          </Button>
        </Box>
        <Box>
          <Button onClick={handleBack} size="small" sx={{ marginY: '2%', fontWeight: 'bold' }} variant="text">
            {'← Atras'}
          </Button>
          <TextField
            sx={{ width: '100%', marginY: '2%' }}
            label="RUT"
            variant="outlined"
            value={RUT}
            onChange={changeRUT}
            error={RUTError !== ''}
            helperText={RUTError}
          />
          <TextField
            sx={{ width: '100%', marginY: '2%' }}
            label="Telefono celular"
            variant="outlined"
            value={phone}
            inputProps={{ inputMode: 'numeric' }}
            onChange={changePhone}
            error={phoneError !== ''}
            helperText={phoneError}
          />
          <TextField
            value={name}
            onChange={changeName}
            sx={{ width: '100%', marginY: '2%' }}
            label="Nombre"
            variant="outlined"
          />
          <TextField
            value={lastName}
            onChange={changeLastName}
            sx={{ width: '100%', marginY: '2%' }}
            label="Apellido"
            variant="outlined"
          />
          <TextField
            value={password}
            onChange={changePassword}
            sx={{ width: '100%', marginY: '2%' }}
            label="Contraseña"
            type="password"
          />
          <TextField
            value={confirmation}
            onChange={changeConfirmation}
            sx={{ width: '100%', marginY: '2%' }}
            label="Confirma tu contraseña"
            type="password"
            error={passwordError !== ''}
            helperText={passwordError}
          />
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={12}>
            <Button
              disabled={
                RUT === '' ||
                RUTError !== '' ||
                name === '' ||
                lastName === '' ||
                password === '' ||
                confirmation !== password ||
                passwordError !== '' ||
                phone === '' ||
                phoneError !== ''
              }
              onClick={handleNext}
              size="large"
              sx={{ marginY: '2%', fontWeight: 'bold', borderRadius: '50px' }}
              variant="contained">
              {'Siguiente →'}
            </Button>
          </Stack>
        </Box>
        <Box>
          <Button onClick={handleBack} size="small" sx={{ marginY: '2%', fontWeight: 'bold' }} variant="text">
            {'← Atras'}
          </Button>
          <TextField
            value={weight}
            onChange={changeWeight}
            sx={{ width: '100%', marginY: '2%' }}
            label="Peso"
            variant="outlined"
            inputProps={{ inputMode: 'numeric' }}
            InputProps={{
              endAdornment: <InputAdornment position="start">kg</InputAdornment>,
            }}
          />
          <TextField
            value={height}
            onChange={changeHeight}
            sx={{ width: '100%', marginY: '2%' }}
            label="Altura"
            variant="outlined"
            inputProps={{ inputMode: 'numeric' }}
            InputProps={{
              endAdornment: <InputAdornment position="start">cm</InputAdornment>,
            }}
          />
          <p>Fecha de nacimiento</p>
          <DateInput onChange={changeBirthday} />
          <FormControl sx={{ width: '100%', marginY: '2%' }}>
            <InputLabel id="Sselect-label">Sexo</InputLabel>
            <Select
              value={sex}
              onChange={changeSex}
              labelId="Sselect-label"
              id="simple-select"
              label="Sexo"
              variant="outlined"
              sx={{ width: '100%' }}>
              <MenuItem value="F">Mujer</MenuItem>
              <MenuItem value="M">Hombre</MenuItem>
              <MenuItem value="-">Prefiero no decir</MenuItem>
            </Select>
          </FormControl>
          <FormGroup>
            <p>
              Marque la casilla correspondiente si ha sido <b>diagnosticado por un médico</b> con alguna de las
              siguientes enfermedades
            </p>
            <FormControlLabel
              onChange={() => setDiabetes(diabetes === false)}
              control={<Checkbox checked={diabetes} />}
              label="Diabetes"
            />
            <FormControlLabel
              onChange={() => setThrombosis(thrombosis === false)}
              control={<Checkbox checked={thrombosis} />}
              label="Antencedentes de Trombosis"
            />
            <FormControlLabel
              onChange={() => setHeartDisease(heartDisease === false)}
              control={<Checkbox checked={heartDisease} />}
              label="Antencedentes de Enfermedades Cardiovasculares"
            />
          </FormGroup>
          <TextField
            value={otherDiseases}
            onChange={changeOtherDiseases}
            sx={{ width: '100%', marginY: '2%' }}
            label="Otras enfermedades o antecedentes"
            variant="outlined"
          />
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={12}>
            <Button
              disabled={birthday === null || sex === '' || height === '' || weight === ''}
              onClick={handleNext}
              size="large"
              sx={{ marginY: '2%', fontWeight: 'bold', borderRadius: '50px' }}
              variant="contained">
              {'Siguiente →'}
            </Button>
          </Stack>
        </Box>
        <Box>
          <Button onClick={handleBack} size="small" sx={{ marginY: '2%', fontWeight: 'bold' }} variant="text">
            {'← Atras'}
          </Button>
          <Terms content={terms?.content || ''} />
          <Box sx={{ marginX: '5vw' }}>
            <FormControlLabel
              sx={{ marginY: '16px' }}
              onChange={() => setAccept(accept === false)}
              control={<Checkbox checked={accept} />}
              label="He leido y acepto los términos y condiciones de uso"
            />
          </Box>
          {default_department === undefined ? (
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={12}>
              <Button
                disabled={accept === false}
                onClick={handleNext}
                size="large"
                sx={{ marginY: '2%', fontWeight: 'bold', borderRadius: '50px' }}
                variant="contained">
                {'Siguiente →'}
              </Button>
            </Stack>
          ) : (
            <Button
              onClick={register}
              size="large"
              sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '50px' }}
              disabled={
                RUT === '' ||
                RUTError !== '' ||
                name === '' ||
                lastName === '' ||
                password === '' ||
                confirmation !== password ||
                birthday === null ||
                passwordError !== '' ||
                sex === '' ||
                height === '' ||
                weight === '' ||
                activationCode === '' ||
                accept === false
              }
              variant="contained">
              EMPEZAR
            </Button>
          )}
        </Box>
        {default_department === undefined ? (
          <Box>
            <Button onClick={handleBack} size="small" sx={{ marginY: '2%', fontWeight: 'bold' }} variant="text">
              {'← Atras'}
            </Button>
            <TextField
              value={activationCode}
              onChange={changeActivationCode}
              sx={{ width: '100%', marginY: '2%' }}
              label="Codigo Activacion"
              variant="outlined"
            />
            <Typography sx={{ color: 'red' }}>{submitError}</Typography>
            <Button
              onClick={register}
              size="large"
              sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '50px' }}
              disabled={
                RUT === '' ||
                RUTError !== '' ||
                name === '' ||
                lastName === '' ||
                password === '' ||
                confirmation !== password ||
                birthday === null ||
                passwordError !== '' ||
                sex === '' ||
                height === '' ||
                weight === '' ||
                activationCode === '' ||
                accept === false
              }
              variant="contained">
              EMPEZAR
            </Button>
          </Box>
        ) : (
          <Box></Box>
        )}
      </SwipeableViews>
    </Box>
  );
};

export default UserRegister;
