import React, { FC } from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { BadgeDirectory } from './BadgeDirectory';

interface Props {
  badges: number[];
}

const SmallBadges: FC<Props> = (props: Props) => {
  return (
    <AvatarGroup max={4} spacing={22}>
      {BadgeDirectory.map((badge, index) => {
        if (props.badges.includes(badge.id)) {
          return <Avatar key={`badge-${index}`} alt={badge.title} src={badge.imgSrcSm} />;
        }
      })}
    </AvatarGroup>
  );
};

export default SmallBadges;
