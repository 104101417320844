import React, { FC, Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../redux/store';
import { AppBar, Container, Toolbar, Box } from '@mui/material';
import { Chip } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import makana from './../images/logo.png';
import makana_small from './../images/logo_small.png';
import Timer from '../components/OtherComponents/Timer';
import { useDispatch } from 'react-redux';
import { userActions } from '../redux/slices/userSlice';
import languages from '../language/languages';

const MakanaBar: FC = () => {
  const { mode } = useSelector((state: RootState) => state.user);
  const id = useSelector((state: RootState) => state.protocol.protocol?.id);
  const { selected } = useSelector((state: RootState) => state.language);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    // dispatch(userActions.setMode({ mode: '' }));
    if (id !== undefined) {
      dispatch(userActions.setMode({ mode: '' }));
    }
  }, []);

  const exit = () => {
    navigate(`/out/${window.location.pathname.substring(9)}`);
    dispatch(userActions.setMode({ mode: '' }));
  };

  return (
    <Fragment>
      {location.pathname !== '/login' &&
        location.pathname !== '/' &&
        location.pathname !== '/register' &&
        location.pathname !== '/recover_password' && (
          <AppBar sx={{ background: 'white' }}>
            <Container>
              <Toolbar sx={{ postition: 'relative', top: '0' }}>
                {window.location.pathname.includes('routine') || mode === 'active-out' ? (
                  <img src={makana_small} height={'30px'} />
                ) : (
                  <img src={makana} height={'30px'} />
                )}
                <Box sx={{ marginLeft: '10px', paddingBottom: '0', marginTop: '8px' }}>
                  {(window.location.pathname.includes('routine') || mode === 'active-out') && <Timer />}
                </Box>
                <Box sx={{ position: 'fixed', right: '10vw', top: '16px' }}>
                  {(window.location.pathname.includes('routine') || mode === 'active-out') && (
                    <Box>
                      <Chip
                        onClick={exit}
                        color="error"
                        icon={<ExitToAppIcon />}
                        label={languages[selected].others.exit}
                      />
                    </Box>
                  )}
                </Box>
              </Toolbar>
            </Container>
          </AppBar>
        )}
    </Fragment>
  );
};

export default MakanaBar;
