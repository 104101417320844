import React, { FC, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { bindActionCreators } from 'redux';
import { Card, CardActionArea, CardContent, Chip, Typography, Link } from '@mui/material';
import Fade from '@mui/material/Fade';
import { Box } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import languages from '../../language/languages';
import * as planInteractors from '../../redux/interactors/PlanInteractors';

interface Props {
  getPlanInteractor: typeof planInteractors.getPlanInteractor;
}

const PlanPreview: FC<Props> = (props: Props) => {
  const user = useSelector((state: RootState) => state.user);
  const plan = useSelector((state: RootState) => state.plan);
  const { selected } = useSelector((state: RootState) => state.language);
  useEffect(() => {
    if (user.token) {
      props.getPlanInteractor([]);
    }
  }, []);
  return (
    <Fade in={true}>
      <Card
        sx={{
          gap: 2,
          spacing: 2,
          width: '100vw',
          position: 'relative',
          top: '0',
          border: 'none',
          boxShadow: 'none',
          backgroundImage: 'transparent',
        }}>
        <Box
          sx={{
            borderRadius: '20px',
            backgroundImage: 'linear-gradient(to bottom right,#88b849, #7be7c6)',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            margin: { xs: '0 5vw 5vw 5vw', sm: '0 5% 5% 5%' },
            zIndex: '4',
            position: 'relative',
            top: '0',
            textAlign: 'left',
            color: 'white',
          }}>
          <CardContent>
            <Box sx={{ marginX: { xs: '5vw', sm: '5px' }, marginY: '0', paddingY: '0' }}>
              <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center' }}>
                <ReactMarkdown className="mdp">{languages[selected].profile.plan.title}</ReactMarkdown>
                <Chip
                  color="info"
                  label={plan.plan ? languages[selected].profile.plan.badge(plan.plan) : '-'}
                  sx={{ margin: '0 10px' }}
                />
              </Typography>
              {plan.plan && (
                <ReactMarkdown className="md">{languages[selected].profile.plan.description(plan.plan)}</ReactMarkdown>
              )}
              <Typography variant="h6">
                <ReactMarkdown className="mdp">{languages[selected].profile.plan.priorities}</ReactMarkdown>
              </Typography>
              <Chip
                color="info"
                label={plan.priority1 ? `1 - ${languages[selected].profile.plan.priorityBadge(plan.priority1)}` : '-'}
                sx={{ marginRight: { xs: '5vw', sm: '5px' } }}
              />
              <Chip
                color="info"
                label={plan.priority2 ? `2 - ${languages[selected].profile.plan.priorityBadge(plan.priority2)}` : '-'}
              />
            </Box>
          </CardContent>
          <CardActionArea sx={{ width: '100%', paddingBottom: { xs: '5vw', sm: '25px' } }}>
            <Link
              sx={{
                color: 'white',
                minWidth: '60vw',
                fontWeight: 'bold',
                boxShadow: 'none',
                marginX: { xs: '10vw', sm: '25px' },
                textDecoration: 'none',
                fontSize: '1rem',
                borderBottom: '2px solid white',
              }}
              href={`/update_plan`}>
              {languages[selected].profile.plan.editCta} &gt;
            </Link>
          </CardActionArea>
        </Box>
      </Card>
    </Fade>
  );
};

const mapDispatchToProps = (dispatch: any): Props => ({
  ...bindActionCreators(
    {
      ...planInteractors,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(PlanPreview);
