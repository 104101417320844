import React, { FC } from 'react';
import { Button } from '@mui/material';
import languages from '../../language/languages';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const NotificationsButton: FC = () => {
  const { selected } = useSelector((state: RootState) => state.language);

  return (
    <Button
      sx={{
        color: 'white',
        backgroundImage: 'linear-gradient(90deg, #fcff1e 0%, #db840c 35%, #ff2800 100%);',
        fontWeight: 'bold',
        borderRadius: '50px',
        marginBottom: '3vh',
        width: '90vw',
        marginX: '5vw',
        maxWidth: '480px',
        height: '8vh',
        '&:hover': {
          color: '#eee',
        },
      }}
      className="progressier-subscribe-button"
      data-icons="false"
      data-eligible="Get notifications"
      data-subscribed="Subscribed"
      data-blocked="Notifications blocked">
      {languages[selected].profile.buttons.notifications}
    </Button>
  );
};

export default NotificationsButton;
