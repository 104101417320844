import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { Box, Button, TextField } from '@mui/material';
import userService from '../services/UserServices';
import { useNavigate } from 'react-router-dom';
import { userActions } from '../redux/slices/userSlice';
import { useDispatch } from 'react-redux';

const RecoverPassword: FC = () => {
  const [email, setEmail] = useState<string | undefined>('');
  const [emailError, setEmailError] = useState('');
  const [useEmail, setUseEmail] = useState(false);
  const [phone, setPhone] = useState<string | undefined>('569');
  const [phoneError, setPhoneError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch(userActions.logout());
  }, []);

  const recover = () => {
    if (useEmail) {
      userService.recoverPasswordEmail(email);
    } else {
      userService.recoverPassword(phone);
    }
  };
  const changeEmail = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      setEmailError('');
    } else {
      setEmailError('Formato no válido');
    }
    setEmail(event.target.value.toLocaleLowerCase());
  };
  const changePhone = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      setPhone(event.target.value);
      setPhoneError('');
    } else {
      setPhoneError('Error al ingresar el número');
    }
  };
  const login = () => {
    navigate('/login');
  };

  const changeUseEmail = () => {
    setUseEmail(!useEmail);
    setPhone('569');
    setPhoneError('');
    setEmail('');
    setEmailError('');
  };

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        textAlign: 'left',
      }}>
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Box sx={{ padding: '5vh' }}>
          {!useEmail ? (
            <Box>
              <Box sx={{ paddingY: '5vh' }}>
                <h2 className="App-logo-text">Revisa tu whatsapp y sigue las Instrucciones</h2>
                <Box>
                  <h4 className="App-logo-text">
                    Si en 5 minutos NO has recibido un whatsapp, presiona Enviar Código otra vez
                  </h4>
                </Box>
              </Box>
              <TextField
                sx={{ width: '100%', marginY: '2%' }}
                label="Telefono celular"
                variant="outlined"
                value={phone}
                inputProps={{ inputMode: 'numeric' }}
                onChange={changePhone}
                error={phoneError !== ''}
                helperText={phoneError}
              />
            </Box>
          ) : (
            <Box>
              <Box sx={{ paddingY: '5vh' }}>
                <h2 className="App-logo-text">Revisa tu email y sigue las Instrucciones</h2>
                <Box>
                  <h4 className="App-logo-text">Si en 5 minutos NO has recibido un email, presiona Reenviar Código</h4>
                </Box>
              </Box>
              <TextField
                sx={{ width: '100%', marginY: '2%' }}
                label="Email"
                variant="outlined"
                value={email}
                onChange={changeEmail}
                error={emailError !== ''}
                helperText={emailError}
              />
            </Box>
          )}
          <Box sx={{ marginY: '5vh' }}>
            <Button
              disabled={phoneError !== '' || emailError !== '' || (phone === '569' && email === '')}
              onClick={recover}
              size="large"
              sx={{ borderRadius: '50px', width: '100%', marginY: '2%', fontWeight: 'bold' }}
              variant="contained">
              ENVIAR CODIGO
            </Button>
          </Box>
          <Box sx={{ marginY: '5vh' }}>
            {useEmail ? (
              <Button size="large" onClick={changeUseEmail} sx={{ width: '100%', marginY: '2%', fontWeight: 'bold' }}>
                Recuperar con WhatsApp
              </Button>
            ) : (
              <Button size="large" onClick={changeUseEmail} sx={{ width: '100%', marginY: '2%', fontWeight: 'bold' }}>
                Recuperar con Email
              </Button>
            )}
            <Button size="large" onClick={login} sx={{ width: '100%', marginY: '2%', fontWeight: 'bold' }}>
              Volver al inicio
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RecoverPassword;
