import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../redux/store';
import { Box, Button } from '@mui/material';
import UpdatePriority from '../components/WorkoutPlan/UpdatePriority';
import userService from '../services/UserServices';
import stock_7 from './../images/stock_7.png';

const setPriorityPartId = (priority: string) => {
  switch (priority.substring(0, 2)) {
    case 'l1':
      return 2;
    case 'l2':
      return 1;
    case 'l3':
      return 5;
    case 'l4':
      return 4;
    case 'l5':
      return 8;
    case 'l6':
      return 3;
    case 'l7':
      return 9;
    case 'l8':
      return 10;
    default:
      return 11;
  }
};

const UpdatePlan: FC = () => {
  const token = useSelector((state: RootState) => state.user.token);
  const navigate = useNavigate();
  const [risk, setRisk] = useState('');
  const [priority1, setPriority1] = useState('');
  const [priority2, setPriority2] = useState('');
  const [id, setId] = useState(0);
  const plan = async () => {
    const response = await userService.getUserPlan();
    if (response.plan) {
      setPriority1(response.plan.priority_1);
      setPriority2(response.plan.priority_2);
      setRisk(response.plan.plan);
      setId(+response.plan.id);
    }
  };
  useEffect(() => {
    plan();
  }, []);

  const handlePriority1 = (priority: string) => {
    setPriority1(priority);
  };

  const handlePriority2 = (priority: string) => {
    setPriority2(priority);
  };

  const submitUpdate = async () => {
    if (token) {
      const response = await userService.updateUserPlan(
        {
          id: id,
          plan: risk,
          priority_1: priority1,
          priority_2: priority2,
          priority_part_1_id: setPriorityPartId(priority1),
          priority_part_2_id: setPriorityPartId(priority2),
        },
        token,
      );
      if (response) {
        navigate('/profile');
      }
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        minWidth: '100vw',
        height: '100%',
        width: '100%',
        textAlign: 'left',
        backgroundImage: 'linear-gradient(to bottom right,#FFF,#e1f7f6)',
      }}>
      <Box
        sx={{
          backgroundImage: `url(${stock_7})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'left bottom',
          minHeight: '100vh',
          minWidth: '100vw',
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Box sx={{ paddingY: '10vh' }}>
          {priority1 !== '' && (
            <UpdatePriority
              title="Prioridad 1"
              description="Elige una parte del cuerpo en la que quieras enfocar tus rutinas"
              blockedPreference={priority2.substring(0, 2)}
              priority={priority1}
              onChange={handlePriority1}
            />
          )}
          {priority2 !== '' && (
            <UpdatePriority
              title="Prioridad 2"
              description="Esta prioridad no puede ser igual a la primera"
              blockedPreference={priority1.substring(0, 2)}
              priority={priority2}
              onChange={handlePriority2}
            />
          )}
          <Box sx={{ width: '100vw', paddingX: '5vw', paddingTop: '5vw' }}>
            <Button
              onClick={submitUpdate}
              variant="contained"
              size="large"
              sx={{ borderRadius: '50px', fontWeight: 'bold', width: '100%' }}>
              Actualizar
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UpdatePlan;
