// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck projectActions properties generated at runtime
import { buildInteractor, buildInteractorDirectAction } from './base';
import { rankingActions } from '../slices/rankingSlice';
import rankingService from '../../services/RankingService';

export const getRankingInteractor = buildInteractor(
  rankingActions.loadingGetRanking,
  rankingActions.successGetRanking,
  rankingActions.errorGetRanking,
  rankingService.getUserRanking,
);
export const setStagingPointsInteractor = buildInteractorDirectAction(rankingActions.setStagingPoints);
