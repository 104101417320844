import React, { FC } from 'react';
import { RootState } from '../../redux/store';
import { useSelector, useDispatch } from 'react-redux';
import { bodyActions } from '../../redux/slices/bodySlice';
import ProtocolCard from '../OtherComponents/Card';
import { Box, Drawer, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { es } from '../../language/es';
import languages from '../../language/languages';
import './Menu.css';

// https://codepen.io/kylelavery88/pen/WdmVJL
// https://mui.com/material-ui/react-drawer/

const bodyInterface: { [index: string]: any } = {
  head: es.body.bodyParts.head,
  trapezius: es.body.bodyParts.trapezius,
  chest: es.body.bodyParts.chest,
  stomach: es.body.bodyParts.stomach,
  leftShoulder: es.body.bodyParts.leftShoulder,
  leftArm: es.body.bodyParts.leftArm,
  leftHand: es.body.bodyParts.leftHand,
  leftLeg: es.body.bodyParts.leftLeg,
  leftFoot: es.body.bodyParts.leftFoot,
  rightShoulder: es.body.bodyParts.rightShoulder,
  rightArm: es.body.bodyParts.rightArm,
  rightHand: es.body.bodyParts.rightHand,
  rightLeg: es.body.bodyParts.rightLeg,
  rightFoot: es.body.bodyParts.rightFoot,
  lowerBack: es.body.bodyParts.lowerBack,
  upperBack: es.body.bodyParts.upperBack,
};

const unsafeProtocols = [5, 9, 12, 18, 19, 20, 21, 22, 23, 25];

const PopUpMenu: FC = () => {
  const { selected } = useSelector((state: RootState) => state.language);
  const menuHidden = useSelector((state: RootState) => state.body.menuHidden);
  const selectedPart = useSelector((state: RootState) => state.body.selectedPart);
  const protocols = useSelector((state: RootState) => state.protocol.protocols);
  const { diabetes, thrombosis, heart_diseases } = useSelector((state: RootState) => state.user);
  let selectedProtocols = protocols.filter((protocol) => protocol.bodyPart === bodyInterface[selectedPart]);
  if (diabetes === true || thrombosis === true || heart_diseases === true) {
    selectedProtocols = selectedProtocols.filter((protocol) => !unsafeProtocols.includes(+protocol.id));
  }
  const dispatch = useDispatch();

  const clickHandler = () => {
    dispatch(bodyActions.unselect());
  };

  const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    dispatch(bodyActions.unselect());
  };

  return (
    <Drawer
      sx={{ zIndex: '10100', borderRadius: '200px' }}
      anchor="bottom"
      open={menuHidden === false}
      onClose={toggleDrawer()}>
      <div className="menu__header">
        <h2 className="App-logo-text">
          {languages[selected].body.menuTitle} - {languages[selected].body.bodyParts[selectedPart]}
        </h2>
        <IconButton onClick={clickHandler} color="primary" aria-label="close-menu" component="label">
          <CloseIcon />
        </IconButton>
      </div>
      <Box
        sx={{
          display: 'flex',
          paddingBottom: '10vh',
          gap: 1,
          py: 1,
          overflow: 'auto',
          width: '100vw',
          scrollSnapType: 'x mandatory',
          paddingX: '10vw',
          '& > *': {
            scrollSnapAlign: 'center',
          },
          '::-webkit-scrollbar': { display: 'none' },
        }}>
        {selectedProtocols.map((prot, index) => (
          <ProtocolCard specs={prot} key={`prot${prot.id}-${index}`} />
        ))}
      </Box>
    </Drawer>
  );
};

export default PopUpMenu;
