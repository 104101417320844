import React, { FC } from 'react';
import Fade from '@mui/material/Fade';
import { Box } from '@mui/material';

interface Props {
  title: string;
  description: string;
  imgSrc: string;
  earned: boolean;
  congratulations: string;
  background: string;
}
const BadgeCard: FC<Props> = (props: Props) => {
  return (
    <Fade in={true}>
      <Box
        sx={{
          borderRadius: '20px',
          backgroundImage: props.earned ? props.background : 'linear-gradient(to bottom right,#606060, #aaaaaa)',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          margin: { xs: '0 5vw 5vw 5vw', sm: '0 5% 5% 5%' },
          zIndex: '4',
          position: 'relative',
          top: '0',
        }}>
        <Box sx={{ marginX: '0vw', marginY: '0', paddingY: '0', display: 'flex', flexDirection: 'row' }}>
          <img
            src={props.imgSrc}
            alt="badge"
            style={{ borderRadius: '20px 20px 0 0', padding: '4vw', maxWidth: '30vw', maxHeight: '30vw' }}
          />
          <Box sx={{ padding: '1vw', display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
            <h2 className="App-profile-text" style={{ padding: 0 }}>
              {props.title}
            </h2>
            {props.earned ? (
              <h4 className="App-profile-text" style={{ padding: '0 0 12px 0' }}>
                {props.congratulations}
              </h4>
            ) : (
              <h4 className="App-profile-text" style={{ padding: '0 0 12px 0' }}>
                {props.description}
              </h4>
            )}
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};

export default BadgeCard;
