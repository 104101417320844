import { Language } from '../redux/types/languageTypes';

export const es: Language = {
  home: {
    welcome: (name: string) => `Hola, ${name}!`,
    points: (points: number, position: number) =>
      `🏆 Tienes ${points} puntos en el desafío Makana y estás en el puesto #${position} del ranking!`,
    planCarousel: {
      title: 'Nosotros te recomendamos... 🔥',
      cta0: 'Empecemos!',
      cta1: 'Empecemos!',
      cta2: 'Empecemos!',
      cta3: 'Hagámoslo!',
      cardPhrase: {
        singular: 'ALIVIA TU',
        plural: 'ALIVIA TUS',
        default: 'Continúa con TU plan',
        countPhrase: (count: number, sessions: number) => `Llevas ${count} / ${sessions} sesiones\nesta semana!`,
      },
      bodyParts: {
        neck: 'CUELLO',
        shoulders: 'HOMBROS',
        arms: 'BRAZO / CODO',
        hands: 'MUÑECA / MANO',
        upperBack: 'ESPALDA ALTA',
        lowerBack: 'ESPALDA BAJA',
        legs: 'CADERAS / PIERNAS',
        knees: 'RODILLAS',
        feet: 'TOBILLOS / PIES',
      },
    },
    recommender: {
      title: 'Quieres probar algo DIFERENTE?',
      button: 'ENCUÉNTRALO ACÁ',
    },
  },
  body: {
    menuTitle: 'Rutinas',
    bodyParts: {
      head: 'Cuello',
      trapezius: 'Cuello',
      chest: '',
      stomach: '',
      leftShoulder: 'Hombro',
      leftArm: 'Brazo / Codo',
      leftHand: 'Muñeca / Mano',
      leftLeg: 'Pierna / Rodilla',
      leftFoot: 'Pie / Planta del Pie',
      rightShoulder: 'Hombro',
      rightArm: 'Brazo / Codo',
      rightHand: 'Muñeca / Mano',
      rightLeg: 'Pierna / Rodilla',
      rightFoot: 'Pie / Planta del Pie',
      lowerBack: 'Espalda Baja / Glúteo',
      upperBack: 'Espalda Alta',
    },
  },
  others: {
    cards: {
      cta: 'Ver más',
    },
    exit: 'Salir',
  },
  profile: {
    title: 'Hola',
    sex: (sex: string) => (sex === 'M' ? 'Hombre' : sex === 'F' ? 'Mujer' : '-'),
    diabetes: 'Diabetes',
    thrombosis: 'Trombosis',
    heartDiseases: 'Enfermedades Cardiacas',
    editCta: 'Editar Perfil',
    plan: {
      title: '**TU** Plan: ',
      badge: (plan: string) => {
        let badge = 'PREVENTIVO';
        if (plan === 'c3') {
          badge = 'CORRECTIVO ALTO';
        } else if (plan === 'c2') {
          badge = 'CORRECTIVO MEDIO';
        } else if (plan === 'c1') {
          badge = 'CORRECTIVO BAJO';
        }
        return badge;
      },
      description: (plan: string) => {
        let explanation = `No has reportado molestias considerables, por lo que te recomendamos pasar por el servicio 2 veces por semana para 
            **PREVENIR** molestias en el futuro. **RECUERDA** que tu cuerpo es tu principal herramienta de trabajo!`;
        if (plan === 'c3') {
          // Riesgo alto
          explanation = `Has declarado tener molestias de **INTENSIDAD ALTA**, por lo que como equipo Makana te recomandamos pasar por
              el servicio como mínimo **5 VECES** por semana, y continuar con tu plan en cada sesión hasta **ELIMINAR**
              estas molestias.`;
        } else if (plan === 'c2') {
          // Riesgo medio
          explanation = `Al reportar molestias de **INTENSIDAD MEDIA**, como equipo Makana te recomendamos visitar nuestro servicio un
                mínimo de **4 VECES** por semana. Continúa con tu plan en cada sesión hasta **ELIMINAR** estas molestias.¡Estamos
                aquí para ayudarte!`;
        } else if (plan === 'c1') {
          // Riesgo bajo
          explanation = `Has mencionado tener molestias **LEVES**, por lo que como equipo Makana te recomandamos utilizar el servicio
              por lo menos **3 VECES** por semana, y continuar con tu plan en cada sesión hasta **ELIMINAR** estas
              molestias. **Recuerda** que tu bienestar es esencial para tu desempeño.`;
        }
        return explanation;
      },
      priorities: '**TUS** Prioridades',
      priorityBadge: (priority: string) => {
        switch (priority[1]) {
          case '1':
            return 'Cuello';
          case '2':
            return 'Hombros';
          case '3':
            return 'Brazo / Codo';
          case '4':
            return 'Muñeca / Mano';
          case '5':
            return 'Espalda Alta';
          case '6':
            return 'Espalda Baja';
          case '7':
            return 'Caderas / Piernas';
          case '8':
            return 'Rodillas';
          case '9':
            return 'Tobillos / Pies';
          default:
            return 'No hay preferencia';
        }
      },
      editCta: 'Modificar mi Plan',
    },
    saved: 'Mis Rutinas',
    buttons: {
      notifications: 'Permitir Notificaciones',
      instagram: 'Comunindad Makana',
      facebook: 'Comunidad Makana',
    },
    sofi: 'CUESTIONARIO DE FATIGA',
    sessionsCount: (count: number) => `${count} SESIONES REALIZADAS`,
    nordic: 'PERCEPCIÓN DE MOLESTIAS MUSCULO-ESQUELETICAS',
    logout: 'CERRAR SESIÓN',
  },
  ranking: {
    points: (points: number, position: number) =>
      `Tienes ${points} puntos en el desafío Makana y estás en el puesto #${position} del ranking!`,
    subtitle: 'Sigue sumando puntos para ganar el desafío y ganar increibles premios!',
    badges: {
      title: 'Reconocimientos',
      subtitle:
        '¡Conviértete en un maestro de logros! Gana insignias exclusivas al conquistar desafíos y alcanza un nuevo nivel de BIENESTAR. ¿Estás listo para brillar?',
    },
    terms: {
      cta: 'Ver las Bases del Desafío',
    },
  },
  register: {
    q1: '¿Qué tan **CANSADO** te sientes?',
    q1ScaleLow: 'Cansado',
    q1ScaleHigh: 'Descansado',
    q2: (bodyPart: string) => `¿Cuánta **MOLESTIA FISICA** tienes${bodyPart !== '' ? ` en ${bodyPart}` : ''}?`,
    q2ScaleLow: 'Mucha Molestia',
    q2ScaleHigh: 'Sin Molestia',
    q3In: '¿Cómo te sientes **AHORA** (antes de la sesión)?',
    q3Out: '¿Cómo te sientes **AHORA** (después de la sesión)?',
    q3ScaleLow: 'Muy Mal',
    q3ScaleHigh: 'Muy Bien',
    ctaIn: 'EMPECEMOS',
    ctaOut: 'TERMINAR SESIÓN',
    bodyPart: (url: string) => {
      switch (url[1]) {
        case '1':
          return 'el cuello';
        case '2':
          return 'los hombros';
        case '3':
          return 'el brazo/codo';
        case '4':
          return 'la muñeca/mano';
        case '5':
          return 'la espalda alta';
        case '6':
          return 'la espalda baja';
        case '7':
          return 'las caderas/piernas';
        case '8':
          return 'las rodillas';
        case '9':
          return 'los tobillos/pies';
        default:
          return '';
      }
    },
  },
  nordic: {
    back: 'Atrás',
    next: 'Siguiente',
    intro: {
      title: 'Hola',
      body: 'Contesta el siguiente cuestionario a conciencia para poder recomendarte rutinas a TU medida y tener una **mejor experiencia** en las estaciones de **Makana**',
    },
    body: {
      firstCheck: '12 meses',
      weekCheck: '7 días',
      question: 'Marca los lugares donde hayas sentido molestias o dolores en los últimos',
      cuello: 'Cuello',
      hombro: 'Hombros',
      brazoCodo: 'Brazo/Codo',
      manoMuñeca: 'Mano/Muñeca',
      espaldaAlta: 'Espalda Alta',
      espaldaBaja: 'Espalda Baja',
      caderaPierna: 'Cadera/Pierna',
      rodilla: 'Rodilla',
      tobilloPie: 'Tobillo/Pie',
      scaleQuestion: '¿Cuánto fue la intensidad del dolor en los últimos 7 días?',
      scaleLow: '< BAJO dolor',
      scaleHigh: 'ALTO dolor >',
      intensity: {
        low: 'Intensidad BAJA',
        mid: 'Intensidad MEDIA',
        high: 'Intensidad ALTA',
        veryHigh: 'Intensidad MUY ALTA',
      },
    },
    partDetail: {
      zero: '0 dias',
      week: '1 a 7 dias',
      month: '8 a 30 dias',
      more: 'Más de 30 dias',
      all: 'Todos los dias',
      yes: 'Si',
      no: 'No',
      q1: (tag: string) =>
        `¿Cúal es el tiempo total que has tenido problemas en **${tag}** durante los **últimos 12 meses**?`,
      q2: '¿Tu molestia es producto de un **golpe, caída o torcedura** reciente?',
      q3: '¿Presentas **hinchazón o inflamación** en la zona?',
      lowerBackDetails: 'la parte baja de la espalda',
      neckDetails: 'el cuello',
      shouldersDetails: 'el hombro',
      elbowsDetails: 'el codo',
      handsDetails: 'la mano/muñeca',
      upperBackDetails: 'la parte alta de la espalda',
      legsDetails: 'la cadera/pierna',
      kneesDetails: 'la rodilla',
      anklesDetails: 'el tobillo/pie',
    },
    submit: 'ENVIAR',
    waiting: 'Estamos preparando TU plan...',
    plan: {
      description: (plan: string, priority1: string, priority2: string, x: number, y: number, z: number) => {
        return `${es.nordic.plan.setPriorities(
          es.nordic.plan.priorityBadge(priority1),
          es.nordic.plan.priorityBadge(priority2),
          z,
        )}${es.nordic.plan.setPain(x, y, z)}${es.nordic.plan.setPlan(plan)}${es.nordic.plan.setRandomEnd()}`;
      },
      priorityBadge: (priority: string) => {
        switch (priority[1]) {
          case '1':
            return 'CUELLO';
          case '2':
            return 'HOMBROS';
          case '3':
            return 'BRAZO / CODO';
          case '4':
            return 'MUÑECA / MANO';
          case '5':
            return 'ESPALDA ALTA';
          case '6':
            return 'ESPALDA BAJA';
          case '7':
            return 'CADERA / PIERNAS';
          case '8':
            return 'RODILLAS';
          case '9':
            return 'TOBILLOS / PIES';
          default:
            return 'No hay preferencia';
        }
      },
      setPain: (x: number, y: number, z: number) => {
        let pain = '';
        if (y > 0 && y <= 2) {
          pain += '. Además, tienes una molestia aguda';
        } else if (y > 2) {
          pain += '. Además, tienes una molestia prolongada';
        }
        if (x >= 9) {
          pain += ' de **INTENSIDAD ALTA**';
        } else if (x >= 7) {
          pain += ' de **INTENSIDAD MEDIA**';
        } else if (x >= 4) {
          pain += ' de **INTENSIDAD BAJA**';
        } else if (y > 0) {
          pain += ' no significativa';
        }
        if (z > 4 && y > 0) {
          pain += ', considerando además un malestar generalizado';
        }
        return pain;
      },
      setPlan: (plan: string) => {
        let badge =
          ', por lo que  te hemos puesto en el plan **PREVENTIVO**, recomendando el uso de makana **2 VECES** por semana para **PREVENIR** molestias en el futuro.';
        if (plan === 'c3') {
          badge =
            ', por lo que  te hemos puesto en el plan **CORRECTIVO ALTO**, recomendando el uso de makana **5 VECES** por semana hasta **ELIMINAR** estas molestias.';
        } else if (plan === 'c2') {
          badge =
            ', por lo que  te hemos puesto en el plan **CORRECTIVO MEDIO**, recomendando el uso de makana **4 VECES**  por semana hasta **ELIMINAR** estas molestias.';
        } else if (plan === 'c1') {
          badge =
            ', por lo que  te hemos puesto en el plan **CORRECTIVO BAJO**, recomendando el uso de makana **3 VECES**  por semana para **evitar** que estas molestias se vuelvan significativas.';
        }
        return badge;
      },
      setPriorities: (priority1: string, priority2: string, z: number) => {
        if (z === 0) {
          // No prioridades reales, ambas asignadas
          return `Al no presentar molestias significativas, hemos asignado **${priority1}** y **${priority2}** como tus prioridades, pensando en las molestias más comunes en el trabajo`;
        } else if (z === 1) {
          // Solo una prioridad real, otra asignada
          return `Has declarado **${priority1}** como tu prioridad principal. También agregamos **${priority2}** como tu segunda prioridad, pensando en las molestias más comunes en el trabajo`;
        } else {
          // Ambas prioridades reales
          return `De acuerdo a las molestias declaradas, tus prioridades a trabajar son **${priority1}** y **${priority2}**`;
        }
      },
      setRandomEnd: () => {
        const messages = [
          ' Recuerda que **tu bienestar es esencial** para tu desempeño.',
          ' **¡Estamos aquí para ayudarte!**',
          ' Recuerda que **tu cuerpo es tu principal herramienta de trabajo**!',
        ];
        const randomIndex = Math.floor(Math.random() * messages.length);
        return messages[randomIndex];
      },
    },
  },
  cohen: {
    introTitle: 'Cuentanos más',
    introContent: 'Las siguientes preguntas nos sirven para saber cómo te sientes en tu día a día. ¡Vamos a empezar!',
    option1: 'Nunca',
    option2: 'Casi nunca',
    option3: 'De vez en cuando',
    option4: 'A menudo',
    option5: 'Muy a menudo',
    question1:
      'Durante el último mes, ¿con qué frecuencia ha estado afectado por alguna situación que ocurrió inesperadamente?',
    question2:
      'Durante el último mes, ¿con qué frecuencia se ha sentido incapaz de controlar las cosas importantes de su vida?',
    question3: 'Durante el último mes, ¿con qué frecuencia se ha sentido nervioso o estresado?',
    question4:
      'Durante el último mes, ¿con qué frecuencia ha estado seguro sobre su capacidad para manejar sus problemas personales?',
    question5: 'Durante el último mes, ¿con qué frecuencia ha sentido que las cosas le salen bien?',
    question6: 'En el último mes, ¿con qué frecuencia ha sentido no poder afrontar todas las cosas que debía realizar?',
    question7: 'Durante el último mes, ¿con qué frecuencia ha podido controlar las dificultades de su vida?',
    question8: 'Durante el último mes, ¿con qué frecuencia ha sentido que está al control de todo?',
    question9:
      'Durante el último mes, ¿con qué frecuencia se ha sentido molesto porque los sucesos que le han ocurrido estaban fuera de su control?',
    question10:
      'Durante el último mes, ¿con qué frecuencia ha sentido que las dificultades se acumulan tanto que no puede superarlas?',
  },
};

// x=dolor más alto, y=aparicion del dolor, z=cantidad de zonas con dolor.
