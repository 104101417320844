import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { userActions } from '../../redux/slices/userSlice';
import { businessActions } from '../../redux/slices/businessSlice';
import UserInfo from './UserInfo';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import MenuCategory from '../HomeScreenComponents/Category';
import PlanPreview from './PlanPreview';
import { version } from '../../appVersion';
import languages from '../../language/languages';
import NotificationsButton from './NotificationsButton';
import OfflineToggleButton from './OfflineToggleButton';

const UserProfile: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);
  const { selected } = useSelector((state: RootState) => state.language);
  const category = {
    id: 0,
    name: languages[selected].profile.saved,
    protocolIds: user.saveds?.map((saved) => saved.protocol_id),
  };
  const nordic = () => {
    navigate('/nordic');
  };
  const logout = () => {
    dispatch(businessActions.logout());
    dispatch(userActions.logout());
    navigate('/login');
  };
  return (
    <Box sx={{ marginBottom: '10vh' }}>
      <UserInfo />
      <PlanPreview />
      <NotificationsButton />
      <MenuCategory specs={category} />
      <OfflineToggleButton />
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
        <Fade in={true}>
          <Box sx={{ paddingX: '5vw' }}>
            <Stack direction="row" spacing={2} sx={{ marginBottom: '10px', marginX: '10px', maxWidth: '100vw' }}>
              <Box sx={{ width: '100%', borderRadius: '10px', fontWeight: 'bold', boxShadow: 'none' }}>
                <Typography variant="h5" component="div">
                  {user.registerOuts ? languages[selected].profile.sessionsCount(user.registerOuts.length) : ''}
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ margin: '10px' }}>
              <Button
                onClick={nordic}
                sx={{ width: '100%', borderRadius: '10px', fontWeight: 'bold', boxShadow: 'none' }}
                variant="outlined"
                size="large">
                {languages[selected].profile.nordic}
              </Button>
              <Button
                onClick={logout}
                sx={{ width: '100%', borderRadius: '10px', fontWeight: 'bold', boxShadow: 'none' }}
                variant="outlined"
                color="error"
                size="large">
                {languages[selected].profile.logout}
              </Button>
            </Stack>
            {version}
          </Box>
        </Fade>
      </Stack>
    </Box>
  );
};

export default UserProfile;
