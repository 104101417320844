import React, { FC, useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { userActions } from '../../redux/slices/userSlice';
import userService from '../../services/UserServices';
import { Box, TextField, Button } from '@mui/material';

const ActivateUser: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activationCode, setCode] = useState('');
  const changeCode = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCode(event.target.value);
  };
  const activated = () => {
    navigate('/in');
  };
  const token = useSelector((state: RootState) => state.user.token);
  const login = async () => {
    const response = await userService.activateUser(
      activationCode,
      parseInt(activationCode.split('-')[0].substring(1), 16),
      token,
    );
    if (response) {
      const { user } = response;
      user.token = response.token;
      dispatch(userActions.login(user));
      activated();
    }
  };
  return (
    <Box sx={{ minWidth: '320px', display: 'inline-block', padding: '5%' }}>
      <Box sx={{ width: '100%', marginY: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <h1 className="App-logo-text">ACTIVA TU CUENTA</h1>
      </Box>
      <TextField
        sx={{ width: '100%', marginY: '2%' }}
        label="Codigo de Activacion"
        variant="outlined"
        value={activationCode}
        onChange={changeCode}
      />
      <Button
        onClick={login}
        size="large"
        sx={{ borderRadius: '50px', width: '100%', marginY: '2%', fontWeight: 'bold' }}
        variant="contained">
        ACTIVAR
      </Button>
    </Box>
  );
};

export default ActivateUser;
