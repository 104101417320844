import React, { FC } from 'react';
import { Box, Stack, Table, Typography } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import SmallBadges from './SmallBadges';
import badge_2_md from '../../images/badge_2_md.png';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { RankingPosition } from '../../redux/types/rankingTypes';

const Podium: FC = () => {
  const ranking = useSelector((state: RootState) => state.ranking.ranking) || [];
  return (
    <Box
      sx={{
        maxWidth: '100vw',
        width: '100vw',
        minWidth: '310px',
        display: 'inline-block',
        justifyContent: 'center',
        padding: '5%',
        backgroundImage: 'linear-gradient(to bottom,#FFF 0%, #FFF 20%, #333 50%, #333 100%)',
      }}>
      <Box sx={{ fontSize: 90, width: '100%', textAlign: 'center', padding: '5vh 0 5vh 0', position: 'relative' }}>
        🏆
        <img
          src={badge_2_md}
          alt="GanadorDesafío"
          height="65px"
          style={{ position: 'absolute', bottom: '4vh', left: '50%' }}
        />
      </Box>
      <Box sx={{ padding: '2vh 0 2vh 0', position: 'relative' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'end',
            fontWeight: 'bold',
            color: 'white',
            position: 'absolute',
            bottom: '26vw',
            gap: '5%',
            width: '90vw',
          }}>
          {ranking.length >= 2 && (
            <Stack sx={{ alignItems: 'center', width: '30%' }}>
              <SmallBadges badges={ranking[1].badges} />
              <Typography variant="h5" component="div" sx={{ marginTop: '0px', color: 'white' }}>
                {ranking[1].first_name.split(' ')[0]} {ranking[1].last_name.split(' ')[0]}
              </Typography>
            </Stack>
          )}
          {ranking.length >= 1 && (
            <Stack sx={{ alignItems: 'center', width: '30%' }}>
              <SmallBadges badges={ranking[0].badges} />
              <Typography variant="h5" component="div" sx={{ marginTop: '0px', color: 'white' }}>
                {ranking[0].first_name.split(' ')[0]} {ranking[0].last_name.split(' ')[0]}
              </Typography>
            </Stack>
          )}
          {ranking.length >= 3 && (
            <Stack sx={{ alignItems: 'center', width: '30%' }}>
              <SmallBadges badges={ranking[2].badges} />
              <Typography variant="h5" component="div" sx={{ marginTop: '0px', color: 'white' }}>
                {ranking[2].first_name.split(' ')[0]} {ranking[2].last_name.split(' ')[0]}
              </Typography>
            </Stack>
          )}
        </Box>
        <svg
          height="100%"
          strokeMiterlimit="10"
          version="1.1"
          viewBox="0 0 911.118 615"
          width="100%"
          xmlns="https://www.w3.org/2000/svg">
          <defs>
            <linearGradient id="grad1" x1="0%" x2="0%" y1="100%" y2="0%">
              <stop offset="0%" stopColor="#fd1d1d" />
              <stop offset="100%" stopColor="#780000" />
            </linearGradient>
            <linearGradient id="grad2" x1="0%" x2="0%" y1="100%" y2="0%">
              <stop offset="0%" stopColor="#b43a6d" />
              <stop offset="100%" stopColor="#780636" />
            </linearGradient>
            <linearGradient id="grad3" x1="0%" x2="0%" y1="100%" y2="0%">
              <stop offset="0%" stopColor="#fcb035" />
              <stop offset="100%" stopColor="#ad6c02" />
            </linearGradient>
          </defs>
          <clipPath id="ArtboardFrame">
            <rect height="615" width="911.118" x="0" y="0" />
          </clipPath>
          <g clipPath="url(#ArtboardFrame)" id="Capa-1">
            <g opacity="1">
              <path
                d="M315.559 35C315.559 15.67 331.229 1.13687e-13 350.559 1.7053e-13L560.559 1.02318e-12C579.889 1.13687e-12 595.559 15.67 595.559 35L595.559 580C595.559 599.33 579.889 615 560.559 615L350.559 615C331.229 615 315.559 599.33 315.559 580L315.559 35Z"
                fill="url(#grad1)"
                fillRule="nonzero"
                opacity="1"
                stroke="none"
              />
              <path
                d="M631.118 270.636C631.118 251.306 646.788 235.636 666.118 235.636L876.118 235.636C895.448 235.636 911.118 251.306 911.118 270.636L911.118 580C911.118 599.33 895.448 615 876.118 615L666.118 615C646.788 615 631.118 599.33 631.118 580L631.118 270.636Z"
                fill="url(#grad3)"
                fillRule="nonzero"
                opacity="1"
                stroke="none"
              />
              <path
                d="M7.10543e-15 173.88C7.10543e-15 154.55 15.67 138.88 35 138.88L245 138.88C264.33 138.88 280 154.55 280 173.88L280 580C280 599.33 264.33 615 245 615L35 615C15.67 615 2.91323e-13 599.33 2.91323e-13 580L7.10543e-15 173.88Z"
                fill="url(#grad2)"
                fillRule="nonzero"
                opacity="1"
                stroke="none"
              />
              <path
                d="M370.559 510.318C370.559 463.374 408.615 425.318 455.559 425.318C502.503 425.318 540.559 463.374 540.559 510.318C540.559 557.262 502.503 595.318 455.559 595.318C408.615 595.318 370.559 557.262 370.559 510.318Z"
                fill="#f1d244"
                fillRule="nonzero"
                opacity="1"
                stroke="none"
              />
              <path
                d="M55 510.318C55 463.374 93.0558 425.318 140 425.318C186.944 425.318 225 463.374 225 510.318C225 557.262 186.944 595.318 140 595.318C93.0558 595.318 55 557.262 55 510.318Z"
                fill="#dddddd"
                fillRule="nonzero"
                opacity="1"
                stroke="none"
              />
              <path
                d="M686.118 510.318C686.118 463.374 724.174 425.318 771.118 425.318C818.062 425.318 856.118 463.374 856.118 510.318C856.118 557.262 818.062 595.318 771.118 595.318C724.174 595.318 686.118 557.262 686.118 510.318Z"
                fill="#ef9f46"
                fillRule="nonzero"
                opacity="1"
                stroke="none"
              />
            </g>
            <text
              fill="#303030"
              fontFamily="Helvetica"
              fontSize="53.0376"
              opacity="1"
              stroke="none"
              textAnchor="start"
              transform="matrix(1 0 0 1 79.0976 478.125)"
              x="0"
              y="0">
              <tspan textLength="115.01" x="0" y="51">
                {ranking.length >= 2 ? `${ranking[1].total_points} xp` : 0}
              </tspan>
            </text>
            <text
              fill="#303030"
              fontFamily="Helvetica"
              fontSize="53.0376"
              opacity="1"
              stroke="none"
              textAnchor="start"
              transform="matrix(1 0 0 1 396.554 478.125)"
              x="0"
              y="0">
              <tspan textLength="115.01" x="0" y="51">
                {ranking.length >= 1 ? `${ranking[0].total_points} xp` : 0}
              </tspan>
            </text>
            <text
              fill="#303030"
              fontFamily="Helvetica"
              fontSize="53.0376"
              opacity="1"
              stroke="none"
              textAnchor="start"
              transform="matrix(1 0 0 1 711.958 478.125)"
              x="0"
              y="0">
              <tspan textLength="115.01" x="0" y="51">
                {ranking.length >= 3 ? `${ranking[2].total_points} xp` : 0}
              </tspan>
            </text>
          </g>
        </svg>
      </Box>
      {ranking.length >= 10 && (
        <Table>
          <TableBody>
            {ranking.slice(3, 10).map((row: RankingPosition, index: number) => (
              <TableRow key={index}>
                <TableCell align="center" sx={{ fontWeight: 'bold', color: 'white', padding: 0 }}>
                  {index + 4}
                </TableCell>
                <TableCell align="center" sx={{ padding: 0 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, fontWeight: 'bold', color: 'white' }}>
                    <p>
                      {row.first_name.split(' ')[0]} {row.last_name.split(' ')[0]}
                    </p>
                  </Box>
                </TableCell>
                <TableCell align="center" sx={{ padding: 0 }}>
                  <SmallBadges badges={row.badges} />
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold', color: 'white', padding: 0 }}>
                  {row.total_points} xp
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Box>
  );
};

export default Podium;
