import React, { FC, useEffect, useState } from 'react';
import db, { Database, Image } from '../../database/db';

interface Props {
  url: string;
  width?: string;
}

// Componente de React para renderizar las imágenes
const ImageTag: FC<Props> = (props: Props) => {
  const [image, setImage] = useState<Image | null>();

  // Función para recuperar todas las imágenes de la base de datos
  async function recuperarImagenes() {
    try {
      // Obtener todas las imágenes de la base de datos
      const imagenes = (await (db as Database).imagenes.toArray()).filter((img) => img.url === props.url)[0];
      setImage(imagenes);
    } catch (error) {
      console.error('Error al recuperar las imágenes:', error);
    }
  }

  useEffect(() => {
    // Llamar a la función para recuperar las imágenes cuando el componente se monte
    recuperarImagenes();
  }, [props.url]);

  return (
    <img
      key={image?.id}
      src={image?.blob ? URL.createObjectURL(image.blob) : props.url}
      alt="Imagen"
      width={props.width}
      onError={() => setImage(null)}
    />
  );
};

export default ImageTag;
