import { Ranking } from '../types/rankingTypes';
import { createSlice } from '@reduxjs/toolkit';
import { Action, baseRequestStatusReducers } from './base';

const initialState: Ranking = {
  getRankingStatus: {
    loading: false,
    success: false,
    error: false,
  },
};

const successGetRanking = (state: Ranking, action: Action) => {
  return {
    ...state,
    ...action.payload,
    getPlanStatus: { loading: false, success: true, error: false },
  };
};

const RankingSlice = createSlice({
  name: 'ranking',
  initialState,
  reducers: {
    ...baseRequestStatusReducers('getRanking', initialState, null, successGetRanking),
    setStagingPoints: (state: Ranking, action: Action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const rankingActions = RankingSlice.actions;
export const rankingReducer = RankingSlice.reducer;
