export const gifs = [
  'https://cdn.makana.cl/gif/brazocodopistolatensionarco.gif',
  'https://cdn.makana.cl/gif/brazocodopistolatensionsoft.gif',
  'https://cdn.makana.cl/gif/crioterapiabrazocodo.gif',
  'https://cdn.makana.cl/gif/crioterapiahombro.gif',
  'https://cdn.makana.cl/gif/crioterapiamunecamano.gif',
  'https://cdn.makana.cl/gif/crioterapiarodilla.gif',
  'https://cdn.makana.cl/gif/crioterapiatobillopie.gif',
  'https://cdn.makana.cl/gif/cuellopistolatensionplano2.gif',
  'https://cdn.makana.cl/gif/cuellopistolatensionsoft1.gif',
  'https://cdn.makana.cl/gif/espaldabajapistolatensionarco2.gif',
  'https://cdn.makana.cl/gif/espaldabajapistolatensionsoft1.gif',
  'https://cdn.makana.cl/gif/hombropistolatensionarco2.gif',
  'https://cdn.makana.cl/gif/hombropistolatensionsoft1.gif',
  'https://cdn.makana.cl/gif/munecamanopistolatensionpelota2.gif',
  'https://cdn.makana.cl/gif/munecamanopistolatensionsoft1.gif',
  'https://cdn.makana.cl/gif/piepistolatensionarco3.gif',
  'https://cdn.makana.cl/gif/piepistolatensiondedo2.gif',
  'https://cdn.makana.cl/gif/piepistolatensionpelota1.gif',
  'https://cdn.makana.cl/gif/piernapistolatensionarco1.gif',
  'https://cdn.makana.cl/gif/piernapistolatensionarco2.gif',
  'https://cdn.makana.cl/gif/pocketcabezalpelota.gif',
  'https://cdn.makana.cl/gif/pocketcabezalpelotaon.gif',
  'https://cdn.makana.cl/gif/pocketcabezalsoft.gif',
  'https://cdn.makana.cl/gif/pocketcabezalsofton.gif',
  'https://cdn.makana.cl/gif/powercabezalarco.gif',
  'https://cdn.makana.cl/gif/powercabezalarcoon.gif',
  'https://cdn.makana.cl/gif/powercabezalsoft.gif',
  'https://cdn.makana.cl/gif/powercabezalsofton.gif',
  'https://cdn.makana.cl/gif/presoterapiabotasatras.gif',
  'https://cdn.makana.cl/gif/presoterapiabotaspostura.gif',
  'https://cdn.makana.cl/gif/presoterapiaconector.gif',
  'https://cdn.makana.cl/gif/presoterapiafajaatras.gif',
  'https://cdn.makana.cl/gif/presoterapiafajapostura.gif',
  'https://cdn.makana.cl/gif/presoterapiamangaatras.gif',
  'https://cdn.makana.cl/gif/presoterapiamangapostura.gif',
  'https://cdn.makana.cl/gif/presoterapiamodoa6min.gif',
  'https://cdn.makana.cl/gif/presoterapiamodob12min.gif',
  'https://cdn.makana.cl/gif/presoterapiamodob3min.gif',
  'https://cdn.makana.cl/gif/presoterapiamodob6min.gif',
  'https://cdn.makana.cl/gif/presoterapiamodod10min.gif',
  'https://cdn.makana.cl/gif/presoterapiamodod6min.gif',
  'https://cdn.makana.cl/gif/presoterapiamodod8min.gif',
  'https://cdn.makana.cl/gif/rotaheatcuellohombro.gif',
  'https://cdn.makana.cl/gif/rotaheatespaldabaja.gif',
  'https://cdn.makana.cl/gif/rotaheatpierna.gif',
  'https://cdn.makana.cl/gif/venomfaja.gif',
  'https://cdn.makana.cl/gif/venomhombro.gif',
  'https://cdn.makana.cl/gif/venomon.gif',
  'https://cdn.makana.cl/gif/venompresoterapiabotasatras.gif',
  'https://cdn.makana.cl/gif/venompresoterapiabotaspostura.gif',
  'https://cdn.makana.cl/gif/venomrodilla.gif',
];
