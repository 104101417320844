import React, { FC, useState, ChangeEvent, useEffect } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import userService from '../services/UserServices';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userActions } from '../redux/slices/userSlice';

const ResetPassword: FC = () => {
  const [password, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [submitError, setSubmitError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { phone, email, token } = useParams();

  useEffect(() => {
    dispatch(userActions.logout());
  }, []);

  const reset = async () => {
    try {
      const response = await userService.resetPassword({ email, phone }, token, password);
      if (response.status === 'ok') {
        navigate('/login');
      } else {
        setSubmitError('Algo ha salido mal, intenta de nuevo más tarde');
      }
    } catch (e) {
      setSubmitError('Algo ha salido mal, intenta de nuevo más tarde');
    }
  };
  const changePassword = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(event.target.value);
    if (confirmation !== event.target.value) {
      setPasswordError('las contraseñas no coinciden');
    } else {
      setPasswordError('');
    }
  };
  const changeConfirmation = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setConfirmation(event.target.value);
    if (password !== event.target.value) {
      setPasswordError('las contraseñas no coinciden');
    } else {
      setPasswordError('');
    }
  };
  const login = () => {
    navigate('/login');
  };

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        textAlign: 'left',
      }}>
      <Box
        sx={{
          height: '100vh',
          width: '90vw',
          marginX: '5vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Box sx={{ paddingY: '10vh' }}>
          <Box sx={{ paddingY: '5vh' }}>
            <h2 className="App-logo-text">Ingresa tu nueva contraseña</h2>
          </Box>
          <TextField
            value={password}
            onChange={changePassword}
            sx={{ width: '100%', marginY: '2%' }}
            label="Contraseña"
            type="password"
          />
          <TextField
            value={confirmation}
            onChange={changeConfirmation}
            sx={{ width: '100%', marginY: '2%' }}
            label="Confirma tu contraseña"
            type="password"
            error={passwordError !== ''}
            helperText={passwordError}
          />
          <Typography sx={{ color: 'red' }}>{submitError}</Typography>
          <Box sx={{ marginY: '5vh' }}>
            <Button
              onClick={reset}
              size="large"
              sx={{ borderRadius: '50px', width: '100%', marginY: '2%', fontWeight: 'bold' }}
              variant="contained"
              disabled={password === '' || confirmation !== password || passwordError !== ''}>
              RESTABLECER CONTRASEÑA
            </Button>
          </Box>
          <Box sx={{ paddingY: '5vh' }}>
            <h4 className="App-logo-text"></h4>
          </Box>
          <Box sx={{ marginY: '5vh' }}>
            <Button size="large" onClick={login} sx={{ width: '100%', marginY: '2%', fontWeight: 'bold' }}>
              Volver al inicio
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPassword;
