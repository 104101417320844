import React, { FC, Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { userActions } from '../../redux/slices/userSlice';
import userService from '../../services/UserServices';
import { Card, CardActions, CardContent, CardMedia } from '@mui/material';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Fade from '@mui/material/Fade';
import { Grid } from '@mui/material';
import { Box } from '@mui/material';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EmailIcon from '@mui/icons-material/Email';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import StraightenIcon from '@mui/icons-material/Straighten';
import img_1 from './profile.svg';
import stock_5 from './../../images/stock_5.png';
import stock_6 from './../../images/stock_6.png';
import { useNavigate } from 'react-router-dom';
import languages from '../../language/languages';

const UserInfo: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);
  const { selected } = useSelector((state: RootState) => state.language);
  useEffect(() => {
    userService.getProfile(user.token).then((response: any) => {
      console.log(user.token, response);
      const business = { id: response.business.id, name: response.business.name };
      const registerOuts = response.register_outs;
      const saveds = response.saveds;
      dispatch(userActions.profile({ value: { business, registerOuts, saveds } }));
    });
  }, []);

  const updateProfile = () => {
    navigate('/update_profile');
  };
  return (
    <Fade in={true}>
      <Card
        sx={{
          gap: 2,
          spacing: 2,
          width: '100vw', //maxWidth: '480px',
          position: 'relative',
          top: '0',
          border: 'none',
          boxShadow: 'none',
          backgroundImage: 'transparent',
        }}>
        <Fragment>
          <img className="slide_left" src={stock_6} />
          <CardMedia
            sx={{
              padding: '0px',
              marginBottom: '0',
              position: 'relative',
              top: '0',
              zIndex: '2',
              maxWidth: { xs: '100%', sm: '50%' },
            }}
            component="img"
            height="174"
            width="100%"
            alt="default image"
            image={img_1}
          />
          <img className="slide_right" src={stock_5} />
          <Box
            sx={{
              borderRadius: '20px',
              backgroundImage: 'linear-gradient(to bottom right,#0068ff, #7be7c6)',
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
              margin: { xs: '0 5vw 5vw 5vw', sm: '0 5% 5% 5%' },
              zIndex: '4',
              position: 'relative',
              top: '0',
            }}>
            <CardContent>
              <Box sx={{ marginX: '5vw', marginY: '0', paddingY: '0' }}>
                <h2 className="App-profile-text">
                  {languages[selected].profile.title} {user.first_name} {user.last_name}
                </h2>
              </Box>
              <Grid container direction="row" spacing={1} sx={{ marginBottom: '10px' }}>
                <Grid item>
                  <Chip color="info" label={user.email} icon={<EmailIcon />} />
                </Grid>
                <Grid item>
                  {user.sex && (
                    <Chip color="info" label={languages[selected].profile.sex(user.sex)} icon={<AccessibilityIcon />} />
                  )}
                </Grid>
                <Grid item>
                  <Chip
                    color="info"
                    label={`${user.birthday?.substring(8, 10)}-${user.birthday?.substring(
                      5,
                      7,
                    )}-${user.birthday?.substring(2, 4)}`}
                    icon={<CalendarMonthIcon />}
                  />
                </Grid>
                <Grid item>
                  <Chip color="info" label={`${user.height} cm`} icon={<StraightenIcon />} />
                </Grid>
                <Grid item>
                  <Chip color="info" label={`${user.weight} kg`} icon={<FitnessCenterIcon />} />
                </Grid>
                {user.diabetes === true && (
                  <Grid item>
                    <Chip color="info" label={languages[selected].profile.diabetes} />
                  </Grid>
                )}
                {user.thrombosis === true && (
                  <Grid item>
                    <Chip color="info" label={languages[selected].profile.thrombosis} />
                  </Grid>
                )}
                {user.heart_diseases === true && (
                  <Grid item>
                    <Chip color="info" label={languages[selected].profile.heartDiseases} />
                  </Grid>
                )}
                {user.other_diseases !== '' && (
                  <Grid item>
                    <Chip color="info" label={user.other_diseases} />
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <CardActions>
              <Button
                onClick={updateProfile}
                sx={{
                  color: 'primary.main',
                  backgroundColor: 'white',
                  width: '100%',
                  borderRadius: '50px',
                  fontWeight: 'bold',
                  boxShadow: 'none',
                }}
                variant="contained"
                size="large">
                {languages[selected].profile.editCta}
              </Button>
            </CardActions>
          </Box>
        </Fragment>
      </Card>
    </Fade>
  );
};

export default UserInfo;
