import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface Props {
  running: boolean;
  currentStep: number;
  onChange?: (e: any) => void;
}

const StepTimer: FC<Props> = (props: Props) => {
  const routineSteps = useSelector((state: RootState) => state.protocol.protocol?.offline_steps) || [];
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isTimeOut, setTimeOut] = useState(false);

  useEffect(() => {
    setMinutes(routineSteps[props.currentStep].timer.minutes);
    setSeconds(routineSteps[props.currentStep].timer.seconds);
  }, [props.currentStep]);

  useEffect(() => {
    if (isTimeOut) {
      props.onChange && props.onChange({ minutes: 0, seconds: 0 });
      setTimeOut(false);
    }
  }, [isTimeOut, props]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (props.running) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds <= 0) {
            setMinutes((prevMinutes) => {
              if (prevMinutes <= 0) {
                clearInterval(interval);
                setTimeOut(true);
                return 0;
              } else {
                return prevMinutes - 1;
              }
            });
            return minutes > 0 ? 59 : 0;
          } else {
            return prevSeconds - 1;
          }
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [props.running, props.onChange, minutes]);

  return (
    <h2 className="App-logo-text">
      {('0' + minutes).slice(-2)}:{('0' + seconds).slice(-2)}
    </h2>
  );
};

export default StepTimer;
