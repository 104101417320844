import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// Import reducers from ./slices
import { bodyReducer } from './slices/bodySlice';
import { filterReducer } from './slices/filterSlice';
import { protocolReducer } from './slices/protocolSlice';
import { categoryReducer } from './slices/categorySlice';
import { nordicFormReducer } from './slices/nordicFormSlice';
import { userReducer } from './slices/userSlice';
import { sofiFormReducer } from './slices/sofiFormSlice';
import { businessReducer } from './slices/businessSlice';
import { planReducer } from './slices/planSlice';
import { rankingReducer } from './slices/rankingSlice';
import { registerReducer } from './slices/registerSlice';
import { languageReducer } from './slices/languageSlice';
import { cohenReducer } from './slices/cohenSlice';

// Parts of the state you want to persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'body',
    'protocol',
    'category',
    'cohen',
    'filter',
    'nordicForm',
    'user',
    'sofiForm',
    'business',
    'plan',
    'ranking',
    'register',
    'language',
  ],
};

// Add new reducers here
const RootReducer = combineReducers({
  body: bodyReducer,
  protocol: protocolReducer,
  category: categoryReducer,
  filter: filterReducer,
  nordicForm: nordicFormReducer,
  user: userReducer,
  sofiForm: sofiFormReducer,
  business: businessReducer,
  plan: planReducer,
  ranking: rankingReducer,
  register: registerReducer,
  language: languageReducer,
  cohen: cohenReducer,
  // example: exampleReducer,
});

const RootReducerPersistor = persistReducer(persistConfig, RootReducer);

// Export the store to use it in the app
export const store = configureStore({
  reducer: RootReducerPersistor,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export function getCurrentState() {
  return store.getState();
}

export const storePersistor = persistStore(store);

export type RootState = ReturnType<typeof RootReducerPersistor>;
