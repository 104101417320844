import React, { FC, useEffect, useState } from 'react';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { userActions } from '../../redux/slices/userSlice';
import db, { Database } from '../../database/db';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const OfflineToggleButton: FC = () => {
  const { offline } = useSelector((state: RootState) => state.user);
  const [imgCount, setImgCount] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const getImagesCount = async () => {
      const count = await (db as Database).imagenes.count();
      setImgCount(count);
    };

    getImagesCount();
  }, []);

  const toggleOffline = () => {
    dispatch(userActions.setOffline());
  };

  return (
    <Box sx={{ padding: '20px' }}>
      <FormControlLabel
        control={<IOSSwitch sx={{ m: 1 }} checked={offline} />}
        onChange={toggleOffline}
        label="Modo Offline"
      />
      <Box sx={{ textAlign: 'center', fontSize: '10pt' }}>
        {Math.floor((imgCount / 51) * 100)}% descargado Modo Offline
      </Box>
    </Box>
  );
};

export default OfflineToggleButton;
