import React, { FC, useState } from 'react';
import { Box, Stack, Button } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userActions } from '../../redux/slices/userSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import ImageTag from './ImageTag';
import StepTimer from './StepTimer';

const RoutineTimerView: FC = () => {
  const routineSteps = useSelector((state: RootState) => state.protocol.protocol?.offline_steps) || [];
  const [currentStep, setCurrentStep] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [minutes, setMinutes] = useState(routineSteps[currentStep].timer.minutes);
  const [seconds, setSeconds] = useState(routineSteps[currentStep].timer.seconds);
  const [showCTA, setShowCTA] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const skipStep = () => {
    if (currentStep + 1 < routineSteps.length) {
      setCurrentStep(currentStep + 1);
      setMinutes(routineSteps[currentStep + 1].timer.minutes);
      setSeconds(routineSteps[currentStep + 1].timer.seconds);
      setIsRunning(true);
      setShowCTA(false);
    }
  };

  const prevStep = () => {
    if (currentStep - 1 >= 0) {
      setCurrentStep(currentStep - 1);
      setMinutes(routineSteps[currentStep - 1].timer.minutes);
      setSeconds(routineSteps[currentStep - 1].timer.seconds);
      setIsRunning(true);
      setShowCTA(false);
    }
  };

  const handleTimeOut = () => {
    if (routineSteps[currentStep].autoplay && currentStep + 1 < routineSteps.length) {
      setCurrentStep(currentStep + 1);
      setMinutes(routineSteps[currentStep + 1].timer.minutes);
      setSeconds(routineSteps[currentStep + 1].timer.seconds);
      setIsRunning(true);
      setShowCTA(false);
    } else {
      setShowCTA(true);
    }
  };

  const toggleRunning = () => {
    if (!showCTA) {
      setIsRunning(!isRunning);
    } else {
      if (currentStep + 1 < routineSteps.length) {
        setCurrentStep(currentStep + 1);
        setMinutes(routineSteps[currentStep + 1].timer.minutes);
        setSeconds(routineSteps[currentStep + 1].timer.seconds);
        setIsRunning(true);
        setShowCTA(false);
      } else {
        navigate(`/out/${window.location.pathname.substring(9)}`);
        dispatch(userActions.setMode({ mode: '' }));
      }
    }
  };

  return (
    <Box sx={{ color: '#1b4ed8' }}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{
          position: 'fixed',
          bottom: '0',
          width: '100vw',
          borderRadius: '25px 25px 0 0',
          padding: '5vw',
          backgroundColor: 'white',
          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
          zIndex: 9,
        }}>
        <StepTimer running={isRunning} currentStep={currentStep} onChange={handleTimeOut} />
        <Button
          onClick={prevStep}
          sx={{
            position: 'absolute',
            top: 0,
            transform: 'translate(0%, 0%)',
            left: '25%',
            borderRadius: '20px',
            fontWeight: 'bold',
          }}>
          <SkipPreviousIcon />
        </Button>
        <Button
          onClick={skipStep}
          sx={{
            position: 'absolute',
            top: 0,
            transform: 'translate(0%, 0%)',
            right: '25%',
            borderRadius: '20px',
            fontWeight: 'bold',
          }}>
          <SkipNextIcon />
        </Button>
        <Button
          onClick={toggleRunning}
          variant="contained"
          color="primary"
          sx={{
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '20px',
          }}>
          {(seconds > 0 || minutes > 0) && !showCTA ? (
            isRunning ? (
              <PauseIcon />
            ) : (
              <PlayArrowIcon />
            )
          ) : (
            routineSteps[currentStep].cta
          )}
        </Button>
      </Stack>
      <Stack direction="column" spacing={1} alignItems="center" sx={{ padding: '0vw' }}>
        <ImageTag url={routineSteps[currentStep].gif} width="100%" />
      </Stack>
    </Box>
  );
};

export default RoutineTimerView;
