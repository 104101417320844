import React, { FC, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Fade } from '@mui/material';
import { ReactComponent as YourSvg } from '../images/badge_3.svg';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import Confetti from 'react-confetti';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { useNavigate } from 'react-router-dom';

const EarningPoints: FC = () => {
  const { staging_points, position, prev } = useSelector((state: RootState) => state.ranking);
  const [num, setNum] = useState(prev || 0);
  const [allowNext, setAllowNext] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      const interval = setInterval(() => {
        setNum((prevNum) => {
          if (prevNum < ((staging_points || 0) / 16) * 100 && prevNum < 100) {
            return prevNum + 1;
          } else {
            setAllowNext(true);
            clearInterval(interval);
            return prevNum;
          }
        });
      }, 30);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const home = () => {
    navigate('/home');
  };

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        textAlign: 'left',
      }}>
      <Confetti numberOfPieces={500} recycle={false} />
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Box sx={{ paddingY: '10vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h3 className="App-text-display-d3">
            {staging_points && staging_points < 16 ? '¡Buen Trabajo!' : '¡Insaciable!'}
          </h3>
          {staging_points && staging_points < 16 ? (
            <Box sx={{ width: '220px' }}>
              <h3 className="App-text-subhead">
                Has completado un {((staging_points || 0) / 16) * 100}% del desafío Makana
              </h3>
            </Box>
          ) : (
            <Box sx={{ width: '220px', marginBottom: 3 }}>
              <h3 className="App-text-subhead">Ya has completado el 100% del desafío semanal</h3>
            </Box>
          )}
          <svg width={0} height={0}>
            <defs>
              <linearGradient id="my_gradient" x1="0%" y1="0%" x2="50%" y2="100%">
                <stop offset="0%" stopColor="#00E3AE" />
                <stop offset="100%" stopColor="#004CDC" />
              </linearGradient>
            </defs>
          </svg>
          {staging_points && staging_points < 16 && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'center' }}>
              <ElectricBoltIcon />
              <h3 className="App-text-subhead-bold">{num}%</h3>
            </Box>
          )}
          <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <Box
              sx={{
                position: 'absolute',
                width: allowNext ? '156px' : '248px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '28px',
                transition: 'width 0.5s linear',
              }}>
              <YourSvg />
            </Box>
            <CircularProgress
              size={allowNext ? '156px' : '248px'}
              variant="determinate"
              value={100}
              sx={{
                color: '#EBEBF0',
                position: 'absolute',
                'svg circle': { strokeWidth: 3 },
                transition: '0.5s linear',
              }}
            />
            <CircularProgress
              size={allowNext ? '156px' : '248px'}
              variant="determinate"
              value={num}
              sx={{
                'svg circle': { stroke: 'url(#my_gradient)', strokeWidth: 3 },
                transition: '0.5s linear',
                '& .MuiCircularProgress-circle': {
                  transitionTimingFunction: 'linear',
                },
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                width: allowNext ? '156px' : '248px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: allowNext ? '28px' : '80px',
                transition: '0.5s linear',
                backgroundImage: 'radial-gradient(50% 50% at 50% 50%, #FFF 38%, rgba(255, 255, 255, 0.00) 100%);',
              }}>
              <h3 className="App-text-display-d3">{num}%</h3>
            </Box>
          </Box>
          <Fade in={allowNext} timeout={2000}>
            <Box sx={{ justifyContent: 'center', marginTop: 3 }}>
              <h3 className="App-text-subhead-bold m0">#{position} 🏆</h3>
              <h3 className="App-text-subhead">del ranking</h3>
            </Box>
          </Fade>
          <Fade in={allowNext} timeout={3000}>
            <Box
              sx={{
                marginTop: 5,
                paddingTop: '12px',
                justifyContent: 'center',
                width: '90vw',
                borderTop: '1px solid #EBEBF0',
              }}>
              <Button
                variant="contained"
                color="primary"
                onClick={home}
                sx={{
                  width: '100%',
                  borderRadius: 12,
                  textTransform: 'none',
                  fontWeight: 600,
                  boxShadow: 'none',
                }}>
                Finalizar
              </Button>
            </Box>
          </Fade>
        </Box>
      </Box>
    </Box>
  );
};

export default EarningPoints;
