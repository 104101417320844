import React, { FC, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Box, Button, FormControl, Slider, Stack, Table, TableBody, TableCell, TableRow } from '@mui/material';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import * as protocolInteractors from '../../redux/interactors/ProtocolInteractors';
import * as registerInteractors from '../../redux/interactors/registerInteractors';
import ReactMarkdown from 'react-markdown';
import languages from '../../language/languages';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useParams } from 'react-router-dom';

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const customIcons: {
  [index: string]: {
    icon: React.ReactElement;
    label: string;
  };
} = {
  1: {
    icon: <h1 className="npnm">😩</h1>,
    label: '5',
  },
  2: {
    icon: <h1 className="npnm">😞</h1>,
    label: '4',
  },
  3: {
    icon: <h1 className="npnm">😐</h1>,
    label: '3',
  },
  4: {
    icon: <h1 className="npnm">🙂</h1>,
    label: '2',
  },
  5: {
    icon: <h1 className="npnm">😄</h1>,
    label: '1',
  },
};

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

interface Mood {
  emoji: string;
  text: string;
  color: string;
}

interface MoodMapType {
  [key: number]: {
    [key: number]: Mood;
  };
}

const MoodMap: MoodMapType = {
  5: {
    1: {
      emoji: '😡',
      text: 'enojo',
      color: '#ef4416',
    },
    2: {
      emoji: '😰',
      text: 'ansiedad',
      color: '#f56663',
    },
    3: {
      emoji: '😣',
      text: 'frustración',
      color: '#f6a187',
    },
    4: {
      emoji: '😲',
      text: 'sorpresa',
      color: '#fdc64a',
    },
    5: {
      emoji: '😃',
      text: 'alegría',
      color: '#fdb104',
    },
  },
  4: {
    1: {
      emoji: '😟',
      text: 'preocupación',
      color: '#f36f13',
    },
    2: {
      emoji: '😞',
      text: 'decepción',
      color: '#f87b61',
    },
    3: {
      emoji: '😐',
      text: 'indiferecia',
      color: '#fcb48b',
    },
    4: {
      emoji: '🙂',
      text: 'satisfacción',
      color: '#fed273',
    },
    5: {
      emoji: '😊',
      text: 'serenidad',
      color: '#f8c235',
    },
  },
  3: {
    1: {
      emoji: '😓',
      text: 'agobio',
      color: '#c4b8c1',
    },
    2: {
      emoji: '😢',
      text: 'tristeza',
      color: '#e5d5e3',
    },
    3: {
      emoji: '😶',
      text: 'neutralidad',
      color: '#f6f6f6',
    },
    4: {
      emoji: '😌',
      text: 'alivio',
      color: '#e6e3c1',
    },
    5: {
      emoji: '😁',
      text: 'felicidad',
      color: '#d4d3ab',
    },
  },
  2: {
    1: {
      emoji: '😔',
      text: 'desánimo',
      color: '#81a7bc',
    },
    2: {
      emoji: '😕',
      text: 'confusión',
      color: '#8fc6d5',
    },
    3: {
      emoji: '😇',
      text: 'paz',
      color: '#abd3d1',
    },
    4: {
      emoji: '😬',
      text: 'nerviosismo',
      color: '#cadf8e',
    },
    5: {
      emoji: '😍',
      text: 'amor',
      color: '#b7d65d',
    },
  },
  1: {
    1: {
      emoji: '😩',
      text: 'desesperación',
      color: '#297b9f',
    },
    2: {
      emoji: '😴',
      text: 'sueño',
      color: '#81a7bc',
    },
    3: {
      emoji: '😅',
      text: 'alivio',
      color: '#80b8ba',
    },
    4: {
      emoji: '😌',
      text: 'calma',
      color: '#51c496',
    },
    5: {
      emoji: '😴',
      text: 'cansancio',
      color: '#0aa869',
    },
  },
};

interface Props {
  selectProtocolInteractor: typeof protocolInteractors.selectProtocolInteractor;
  updateRegisterInInteractor: typeof registerInteractors.updateRegisterInInteractor;
  postRegisterInInteractor: typeof registerInteractors.postRegisterInInteractor;
  resetRegisterInInteractor: typeof registerInteractors.resetRegisterInInteractor;
}

const MoodMeter: FC<Props> = (props: Props) => {
  const { plan } = useParams();
  const [fatigue, setFatigue] = useState(3);
  const [feeling, setFeeling] = useState(3);
  const [musclePain, setMusclePain] = useState(NaN);
  const { selected } = useSelector((state: RootState) => state.language);
  const bodyPart = languages[selected].register.bodyPart(plan ? plan : '');

  const fatigueHandler = (event: any) => {
    setFatigue(+event.target.value);
    props.updateRegisterInInteractor({ name: 'fatigue', value: +event.target.value });
  };

  const feelingHandler = (event: any) => {
    setFeeling(+event.target.value);
    props.updateRegisterInInteractor({ name: 'general', value: +event.target.value });
  };

  const handlePain = (event: any, value: any) => {
    setMusclePain(+value);
  };

  return (
    <FormControl>
      <Box sx={{ margin: '20px' }}>
        <Typography component="legend">
          <ReactMarkdown className="mdp">{languages[selected].register.q2(bodyPart)}</ReactMarkdown>
        </Typography>
        <StyledRating
          size="large"
          name="highlight-selected-only"
          IconContainerComponent={IconContainer}
          getLabelText={(value: number) => customIcons[value].label}
          highlightSelectedOnly
          value={musclePain}
          onChange={handlePain}
        />
        <Stack direction={'row'} spacing={'50%'}>
          <Typography sx={{ fontSize: 'small', fontWeight: 'bold' }}>
            {languages[selected].register.q2ScaleLow}
          </Typography>
          <Typography sx={{ fontSize: 'small', fontWeight: 'bold' }}>
            {languages[selected].register.q2ScaleHigh}
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ margin: '20px' }}>
        <Typography component="legend">
          <ReactMarkdown className="mdp">¿Cuál es tu **ESTADO DE ÁNIMO**?</ReactMarkdown>
        </Typography>
      </Box>
      <Stack direction={'row'}>
        <Typography
          component="h6"
          sx={{ textOrientation: 'sideways', writingMode: 'vertical-rl', width: 'fit', textAlign: 'center' }}>
          + Energía -
        </Typography>
        <Box sx={{ height: '75vw' }}>
          <Slider orientation="vertical" defaultValue={3} value={fatigue} onChange={fatigueHandler} min={1} max={5} />
        </Box>
        <Box sx={{ position: 'relative' }}>
          <Table sx={{ width: '75vw', height: '75vw' }}>
            <TableBody sx={{ width: '75vw', height: '75vw' }}>
              {[5, 4, 3, 2, 1].map((row) => (
                <TableRow key={`row-${row}`} sx={{ border: 'none', aspectRatio: 1 / 1 }}>
                  {[1, 2, 3, 4, 5].map((col) => (
                    <TableCell
                      key={`row-${row}-${col}`}
                      sx={{
                        backgroundColor: MoodMap[row][col].color,
                        width: '20%',
                        height: '20%',
                        border: 'none',
                      }}></TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box
            sx={{
              backgroundColor: '#FFFFFF08',
              position: 'absolute',
              top: 0,
              width: '75vw',
              height: '75vw',
              backdropFilter: 'blur(10px)',
            }}></Box>
          <Table sx={{ position: 'absolute', top: 0, width: '80vw', height: '80vw' }}>
            <TableBody sx={{ width: '80vw', height: '80vw' }}>
              {[5, 4, 3, 2, 1].map((row) => (
                <TableRow key={`row2-${row}`} sx={{ border: 'none', aspectRatio: 1 / 1 }}>
                  {[1, 2, 3, 4, 5].map((col) => (
                    <TableCell
                      key={`row2-${row}-${col}`}
                      sx={{
                        width: '20%',
                        height: '20%',
                        border: 'none',
                        fontSize: '5em',
                        margin: 0,
                        padding: 0,
                        textAlign: 'center',
                        whiteSpace: 'none',
                      }}>
                      <Box sx={{ transform: 'translate(-0.15em, 0px)' }}>
                        {col === feeling && row === fatigue && MoodMap[row][col].emoji}
                        {col === feeling && row === fatigue && (
                          <Box
                            sx={{
                              backgroundColor: 'primary.main',
                              borderRadius: '5px',
                              fontWeight: 'bold',
                              textTransform: 'capitalize',
                              color: '#FFF',
                              fontSize: 'medium',
                              paddingX: '5px',
                              transform: 'translate(0px, -1em)',
                            }}>
                            {MoodMap[fatigue][feeling].text}
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Stack>
      <Box sx={{ paddingLeft: '15vw' }}>
        <Slider defaultValue={3} onChange={feelingHandler} value={feeling} min={1} max={5} sx={{ maxWidth: '80vw' }} />
        <Typography component="h6" sx={{ textAlign: 'center' }}>
          - Bienestar +
        </Typography>
      </Box>
      {musclePain !== null &&
        feeling !== null &&
        isNaN(fatigue) === false &&
        isNaN(musclePain) === false &&
        isNaN(feeling) === false && (
          <Box sx={{ margin: '20px' }}>
            <Button variant="contained" size="large" sx={{ borderRadius: '50px', fontWeight: 'bold', width: '100%' }}>
              {languages[selected].register.ctaIn}
            </Button>
          </Box>
        )}
    </FormControl>
  );
};

const mapDispatchToProps = (dispatch: any): Props => ({
  ...bindActionCreators(
    {
      ...protocolInteractors,
      ...registerInteractors,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(MoodMeter);
