import { AxiosResponse } from 'axios';
import { axiosBaseInstance } from './config';

const getUserRanking = async (token?: string): Promise<any> => {
  const response: AxiosResponse<{ redirect: any }> = await axiosBaseInstance({
    headers: { 'Content-Type': 'application/json', Authorization: token ? `Bearer ${token}` : '' },
    method: 'get',
    url: `/ranking?since_date=2024-07-01T05:00:01.000Z`,
  });
  return response?.data;
};

const rankingService = {
  getUserRanking,
};

export default rankingService;
