import React, { FC } from 'react';
import { Box } from '@mui/material';
import Protocol from '../components/OtherComponents/Protocol';
import PopUpMenu from '../components/BodyScreenComponents/PopUpMenu';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import Routine from '../components/OtherComponents/Routine';

const RoutineScreen: FC = () => {
  const { offline } = useSelector((state: RootState) => state.user);
  const offline_steps = useSelector((state: RootState) => state.protocol.protocol?.offline_steps) || [];
  return (
    <Box sx={{ width: '100vw', height: '100%', minHeight: '100vh', paddingTop: '60px' }}>
      {offline_steps && offline_steps.length > 0 && offline === true ? <Routine /> : <Protocol />}
      <PopUpMenu />
    </Box>
  );
};

export default RoutineScreen;
