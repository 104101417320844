import React, { FC, useState } from 'react';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

interface Props {
  onChange?: (e: boolean) => void;
}

const RoutineToggle: FC<Props> = (props: Props) => {
  const [timer, setTimer] = useState(true);

  const handleChange = (event: React.MouseEvent<HTMLElement>, newTimer: boolean) => {
    setTimer(newTimer);
    props.onChange && props.onChange(newTimer);
  };
  return (
    <Stack direction="column" spacing={1} alignItems="center" sx={{ paddingBottom: '15px' }}>
      <ToggleButtonGroup exclusive aria-label="text alignment" color="primary">
        <ToggleButton
          onClick={(e) => handleChange(e, true)}
          selected={timer}
          value="left"
          aria-label="centered"
          sx={{ width: '30vw', borderRadius: '26px 0 0 26px !important' }}>
          Pasos
        </ToggleButton>
        <ToggleButton
          onClick={(e) => handleChange(e, false)}
          selected={timer === false}
          value="left"
          aria-label="centered"
          sx={{
            width: '30vw',
            borderRadius: '0 26px 26px 0 !important',
            color: timer === false ? 'red' : 'inherit',
          }}>
          Detalle
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
};

export default RoutineToggle;
