import React, { FC, useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { es } from '../../../language/es';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useNavigate } from 'react-router-dom';

const PlanAnimation: FC = () => {
  const navigate = useNavigate();
  const plan = useSelector((state: RootState) => state.plan);
  const [myPlan, setMyPlan] = useState('');
  const [animatedText, setAnimatedText] = useState('');

  useEffect(() => {
    if (
      plan.plan !== undefined &&
      plan.priority1 !== undefined &&
      plan.priority2 !== undefined &&
      plan.x !== undefined &&
      plan.y !== undefined &&
      plan.z !== undefined
    ) {
      setMyPlan(es.nordic.plan.description(plan.plan, plan.priority1, plan.priority2, plan.x, plan.y, plan.z));
      setAnimatedText('');
    } else {
      setMyPlan('Algo ha salido mal : (');
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (myPlan.length > 0) {
        const charsToAdd = Math.min(Math.floor(Math.random() * (5 - 2 + 1)) + 2, myPlan.length);
        setAnimatedText((prevText) => prevText + myPlan.slice(0, charsToAdd));
        setMyPlan((prevPlan: string) => prevPlan.slice(charsToAdd));
      }
    }, 150);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [myPlan]);

  const returnToHome = () => {
    navigate('/home');
  };

  return (
    <Typography variant="body1" sx={{ textAlign: 'center' }}>
      <ReactMarkdown className="md">{animatedText}</ReactMarkdown>
      {myPlan.length === 0 && (
        <Button
          onClick={returnToHome}
          size="large"
          sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '50px' }}
          variant="contained">
          Volver al INICIO
        </Button>
      )}
    </Typography>
  );
};

export default PlanAnimation;
