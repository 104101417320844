import React, { FC, useState, useEffect } from 'react';
import { Drawer } from '@mui/material';
import { Card, CardHeader } from '@mui/material';
import { Stack, Typography } from '@mui/material';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const terms = `El objetivo principal del Desafío Makana es fomentar la participación activa y el compromiso con los planes asignados, así como promover la limpieza y la interacción dentro del espacio Makana. Mediante un sistema de gamificación, los usuarios acumulan puntos cada semana basados en su desempeño en áreas clave. El 30 de abril, el usuario con más puntos recibirá:
1er lugar: Giftcard para Masaje Relajante en el Hotel y Spa las Taguas para 2 personas.
Un ranking actualizado estará disponible en la app, facilitando a los usuarios el seguimiento de su progreso y la comparación con sus colegas.

### Requisitos de participación
1. Estar registrado dentro de la app Makana.
 
### Maneras de obtener puntos:
 
1. Puntuación semanal por cumplimiento del plan:
Cada semana, los usuarios recibirán puntos según su cumplimiento del plan asignado:
  * 0% de cumplimiento: 0 puntos
  * De 1 a 25% de cumplimiento: 2 puntos.
  * De 26 a 50% de cumplimiento: 4 puntos.
  * De 51 a 75% de cumplimiento: 8 puntos.
  * De 76 a 99% de cumplimiento: 10 puntos.
  * 100% de cumplimiento: 12 puntos.
 
2. Puntuación semanal por registro de salida acorde a la cantidad de sesiones realizadas:
* 4 puntos por realizar el 100% de los registros de salida.
* 2 puntos por realizar el 50% de los registros de salida.

`;

interface Props {
  open: boolean;
  onChange?: (open: boolean) => void;
}

const Terms: FC<Props> = (props: Props) => {
  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);
  const [open, setOpen] = useState(props.open);

  const handleClose = () => {
    setOpen(false);
    if (props.onChange) props.onChange(false);
  };

  return (
    <Drawer
      sx={{ zIndex: '10200', width: '100vw' }}
      PaperProps={{ elevation: 0, style: { backgroundColor: 'transparent' } }}
      open={open}
      anchor="bottom">
      <Card sx={{ width: '100vw', height: '50vh', borderRadius: '25px 25px 0px 0px' }}>
        <div className="scroll-box">
          <CardHeader
            action={
              <IconButton aria-label="settings" onClick={handleClose}>
                <CloseIcon sx={{ color: '#1b4ed8' }} />
              </IconButton>
            }
            title={<Typography variant="h5">Bases Desafío Makana</Typography>}
          />
          <Stack
            direction="column"
            spacing={1}
            sx={{ marginTop: '20px', marginBottom: '80px', overflowY: 'auto', overflowX: 'hidden' }}>
            <Typography variant="body2" sx={{ margin: '7%', padding: '16px' }}>
              <span className="md">
                <ReactMarkdown remarkPlugins={[remarkGfm]}>{terms}</ReactMarkdown>
              </span>
            </Typography>
          </Stack>
        </div>
      </Card>
    </Drawer>
  );
};

export default Terms;
