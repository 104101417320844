import badge_1_sm from '../../images/badge_1_sm.png';
import badge_1_md from '../../images/badge_1_md.png';
import badge_2_sm from '../../images/badge_2_sm.png';
import badge_2_md from '../../images/badge_2_md.png';

interface Badge {
  id: number;
  title: string;
  description: string;
  congratulations: string;
  background: string;
  imgSrcSm: string;
  imgSrcMd: string;
}

export const BadgeDirectory: Badge[] = [
  {
    id: 2,
    title: 'Ganador del Desafío',
    description: 'Para obtener este reconocimiento debes ser el ganador de un desafío de MAKANA.',
    congratulations: '¡Felicidades! Has ganado un desafío de MAKANA. ¡Sigue así!',
    background: 'linear-gradient(to bottom, #992020, #4d0303)',
    imgSrcSm: badge_2_sm,
    imgSrcMd: badge_2_md,
  },
  {
    id: 1,
    title: 'Makana Líder',
    description: 'Para obtener este reconocimiento debes completar el curso MAKANA LIDERES y aprobar el examen final.',
    congratulations: '¡Felicidades! Has completado el curso MAKANA LIDERES y aprobado el examen final. ¡Sigue así!',
    background: 'linear-gradient(to bottom, #1d4e8f, #0d132e)',
    imgSrcSm: badge_1_sm,
    imgSrcMd: badge_1_md,
  },
];
