import React, { FC, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, connect } from 'react-redux';
import { RootState } from '../../redux/store';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, FormControl, Button, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { userActions } from '../../redux/slices/userSlice';
import * as protocolInteractors from '../../redux/interactors/ProtocolInteractors';
import * as registerInteractors from '../../redux/interactors/registerInteractors';
import * as rankingInteractors from '../../redux/interactors/rankingInteractors';
import ReactMarkdown from 'react-markdown';
import languages from '../../language/languages';
import userService from '../../services/UserServices';

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const customIcons: {
  [index: string]: {
    icon: React.ReactElement;
    label: string;
  };
} = {
  1: {
    icon: <h1 className="npnm">😩</h1>,
    label: '5',
  },
  2: {
    icon: <h1 className="npnm">😞</h1>,
    label: '4',
  },
  3: {
    icon: <h1 className="npnm">😐</h1>,
    label: '3',
  },
  4: {
    icon: <h1 className="npnm">🙂</h1>,
    label: '2',
  },
  5: {
    icon: <h1 className="npnm">😄</h1>,
    label: '1',
  },
};

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

interface DispatchProps {
  unselectProtocolInteractor: typeof protocolInteractors.unselectProtocolInteractor;
  updateRegisterOutInteractor: typeof registerInteractors.updateRegisterOutInteractor;
  postRegisterOutInteractor: typeof registerInteractors.postRegisterOutInteractor;
  resetRegisterOutInteractor: typeof registerInteractors.resetRegisterOutInteractor;
  setStagingPointsInteractor: typeof rankingInteractors.setStagingPointsInteractor;
}

const RegisterOutForm: FC<DispatchProps> = (props: DispatchProps) => {
  const user_id = useSelector((state: RootState) => state.user.id);
  const protocol_id = useSelector((state: RootState) => state.protocol.protocol?.id);
  const { plan } = useParams();
  const { selected } = useSelector((state: RootState) => state.language);
  const bodyPart = languages[selected].register.bodyPart(plan ? plan : '');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fatigue, setFatigue] = useState(NaN);
  const [musclePain, setMusclePain] = useState(NaN);
  const [feeling, setFeeling] = useState(NaN);

  const register = async () => {
    const response = await userService.registerOutUser({
      fatigue: 6 - fatigue,
      pain: 6 - musclePain,
      general: 6 - feeling,
      protocol_id: protocol_id,
      user_id: user_id,
    });
    console.log('response', response);
    props.setStagingPointsInteractor({
      attentions: response.staging_points.attentions,
      register_outs: response.staging_points.register_outs,
      staging_points: response.staging_points.points,
      position: response.ranking,
      prev: response.prev,
    });
    props.unselectProtocolInteractor();
    dispatch(userActions.setMode({ mode: '' }));
    dispatch(userActions.setStarted({ started: undefined }));
    navigate('/points');
  };

  const fatigueHandler = (event: any) => {
    setFatigue(+event.target.value);
    props.updateRegisterOutInteractor({ name: 'fatigue', value: +event.target.value });
  };

  const feelingHandler = (event: any) => {
    setFeeling(+event.target.value);
    props.updateRegisterOutInteractor({ name: 'general', value: +event.target.value });
  };

  const musclePainHandler = (event: any) => {
    setMusclePain(+event.target.value);
    props.updateRegisterOutInteractor({ name: 'pain', value: +event.target.value });
  };

  return (
    <FormControl>
      <Box sx={{ margin: '20px' }}>
        <Typography component="legend">
          <ReactMarkdown className="mdp">{languages[selected].register.q1}</ReactMarkdown>
        </Typography>
        <StyledRating
          size="large"
          name="highlight-selected-only"
          value={fatigue}
          IconContainerComponent={IconContainer}
          getLabelText={(value: number) => customIcons[value].label}
          highlightSelectedOnly
          onChange={fatigueHandler}
        />
        <Stack direction={'row'} spacing={'50%'}>
          <Typography sx={{ fontSize: 'small', fontWeight: 'bold' }}>
            {languages[selected].register.q1ScaleLow}
          </Typography>
          <Typography sx={{ fontSize: 'small', fontWeight: 'bold' }}>
            {languages[selected].register.q1ScaleHigh}
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ margin: '20px' }}>
        <Typography component="legend">
          <ReactMarkdown className="mdp">{languages[selected].register.q2(bodyPart)}</ReactMarkdown>
        </Typography>
        <StyledRating
          size="large"
          name="highlight-selected-only"
          IconContainerComponent={IconContainer}
          getLabelText={(value: number) => customIcons[value].label}
          highlightSelectedOnly
          value={musclePain}
          onChange={musclePainHandler}
        />
        <Stack direction={'row'} spacing={'50%'}>
          <Typography sx={{ fontSize: 'small', fontWeight: 'bold' }}>
            {languages[selected].register.q2ScaleLow}
          </Typography>
          <Typography sx={{ fontSize: 'small', fontWeight: 'bold' }}>
            {languages[selected].register.q2ScaleHigh}
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ margin: '20px' }}>
        <Typography component="legend">
          <ReactMarkdown className="mdp">{languages[selected].register.q3Out}</ReactMarkdown>
        </Typography>
        <StyledRating
          size="large"
          name="highlight-selected-only"
          value={feeling}
          IconContainerComponent={IconContainer}
          getLabelText={(value: number) => customIcons[value].label}
          highlightSelectedOnly
          onChange={feelingHandler}
        />
        <Stack direction={'row'} spacing={'50%'}>
          <Typography sx={{ fontSize: 'small', fontWeight: 'bold' }}>
            {languages[selected].register.q3ScaleLow}
          </Typography>
          <Typography sx={{ fontSize: 'small', fontWeight: 'bold' }}>
            {languages[selected].register.q3ScaleHigh}
          </Typography>
        </Stack>
      </Box>
      {fatigue !== null &&
        musclePain !== null &&
        feeling !== null &&
        isNaN(fatigue) === false &&
        isNaN(musclePain) === false &&
        isNaN(feeling) === false && (
          <Box sx={{ margin: '20px' }}>
            <Button
              onClick={register}
              variant="contained"
              size="large"
              sx={{ borderRadius: '50px', fontWeight: 'bold', width: '100%' }}>
              {languages[selected].register.ctaOut}
            </Button>
          </Box>
        )}
    </FormControl>
  );
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  ...bindActionCreators(
    {
      ...protocolInteractors,
      ...registerInteractors,
      ...rankingInteractors,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(RegisterOutForm);
