import React, { FC } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useLocation, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import NavBar from './NavBar';
import MakanaBar from './AppBar';
import Home from '../screens/Home';
import Profile from '../screens/Profile';
import BodyMapScreen from '../screens/BodyMap';
import Login from '../screens/Login';
import NordicScreen from '../screens/Nordic';
import SofiScreen from '../screens/Sofi';
import Register from '../screens/Register';
import RegisterIn from '../screens/RegisterIn';
import RegisterOut from '../screens/RegisterOut';
import Activate from '../screens/Activate';
import PatchRUT from '../screens/PatchRUT';
import PatchPhone from '../screens/PatchPhone';
import RecoverPassword from '../screens/RecoverPassword';
import ResetPassword from '../screens/ResetPassword';
import RoutineScreen from '../screens/Routine';
import TermsAndConditions from '../screens/TermsAndConditions';
import RecommenderScreen from '../screens/Recommender';
import UpdatePlan from '../screens/UpdatePlan';
import ScrollToTop from './ScrollToTop';
import UpdateProfileScreen from '../screens/UpdateProfile';
import Ranking from '../screens/RankingScreen';
import CompleteRegister from '../screens/CompleteRegistrationScreen';
import MoodTest from '../screens/MoodTest';
import AiExplain from '../screens/AiExplain';
import Cohen from '../screens/Cohen';
import EarningPoints from '../screens/EarningPoints';

const NordicRoute: React.FC = () => {
  const location = useLocation();
  const { token } = useSelector((state: RootState) => state.user);

  if (location.search.includes('phone') || token) {
    return <NordicScreen />;
  } else {
    return <Navigate to="/login" />;
  }
};

const myRoutes: FC = () => {
  const { token, business_id, redirect } = useSelector((state: RootState) => state.user);
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <ScrollToTop />
      <MakanaBar />
      <Routes>
        <Route path="/" element={token ? <Navigate to="/home" /> : <Login />} />
        <Route path="/login" element={token ? <Navigate to="/home" /> : <Login />} />
        <Route path="/register" element={token ? <Navigate to="/nordic" /> : <Register />} />
        <Route path="/recover_password" element={<RecoverPassword />} />
        <Route path="/reset_password/:email/:phone/:token" element={<ResetPassword />} />
        <Route path="/reset_password//:phone/:token" element={<ResetPassword />} />
        <Route path="/reset_password/:email//:token" element={<ResetPassword />} />
        <Route path="/activate" element={token ? <Activate /> : <Navigate to="/login" />} />
        <Route path="/profile" element={business_id && token ? <Profile /> : <Navigate to="/login" />} />
        <Route path="/body" element={business_id && token ? <BodyMapScreen /> : <Navigate to="/login" />} />
        <Route path="/nordic" element={<NordicRoute />} />
        <Route path="/sofi" element={business_id && token ? <SofiScreen /> : <Navigate to="/login" />} />
        <Route path="/routine/:plan" element={business_id && token ? <RoutineScreen /> : <Navigate to="/login" />} />
        <Route path="/ai_explain" element={token ? <AiExplain /> : <Navigate to="/login" />} />
        <Route path="/cohen" element={token ? <Cohen /> : <Navigate to="/login" />} />
        <Route path="/points" element={token ? <EarningPoints /> : <Navigate to="/login" />} />
        <Route
          path="/in/:plan"
          element={
            business_id && token && redirect ? (
              redirect === 'nordic' ? (
                <Navigate to="/nordic" />
              ) : redirect === 'sofi' ? (
                <Navigate to="/sofi" />
              ) : (
                <RegisterIn />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/out/:plan"
          element={
            business_id && token && redirect ? (
              redirect === 'sofi' ? (
                <Navigate to="/sofi" />
              ) : (
                <RegisterOut />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/home"
          element={
            business_id && token ? (
              redirect === 'rut' ? (
                <Navigate to="/add_rut" />
              ) : redirect === 'phone' ? (
                <Navigate to="/add_phone" />
              ) : redirect === 'terms' ? (
                <Navigate to="/terms_and_conditions" />
              ) : redirect === 'nordic' ? (
                <Navigate to="/nordic" />
              ) : (
                <Home />
              )
            ) : (
              <Navigate to="/activate" />
            )
          }
        />
        <Route path="/add_rut" element={token ? <PatchRUT /> : <Navigate to="/login" />} />
        <Route path="/add_phone" element={token ? <PatchPhone /> : <Navigate to="/login" />} />
        <Route path="/terms_and_conditions" element={<TermsAndConditions />} />
        <Route path="/charlie" element={<RecommenderScreen />} />
        <Route path="/update_plan" element={token ? <UpdatePlan /> : <Navigate to="/login" />} />
        <Route path="/update_profile" element={token ? <UpdateProfileScreen /> : <Navigate to="/login" />} />
        <Route path="/ranking" element={<Ranking />} />
        <Route path="/complete_registration" element={token ? <Navigate to="/nordic" /> : <CompleteRegister />} />
        <Route path="/salud_mental_prueba" element={<MoodTest />} />
      </Routes>
      {token && business_id && <NavBar />}
    </Router>
  );
};

export default myRoutes;
