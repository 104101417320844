import React, { FC, useEffect } from 'react';
import Fade from '@mui/material/Fade';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import languages from '../../language/languages';
import * as rankingInteractors from '../../redux/interactors/rankingInteractors';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface Props {
  getRankingInteractor: typeof rankingInteractors.getRankingInteractor;
}

const PointsCard: FC<Props> = (props: Props) => {
  const token = useSelector((state: RootState) => state.user.token);
  const { selected } = useSelector((state: RootState) => state.language);
  const { points, position } = useSelector((state: RootState) => state.ranking);
  const navigate = useNavigate();
  useEffect(() => {
    props.getRankingInteractor(token);
  }, []);
  const ranking = () => {
    navigate('/ranking');
  };
  return (
    <Fade in={true}>
      {points !== undefined && position !== undefined ? ( // Add conditional rendering
        <Box
          onClick={ranking}
          sx={{
            borderRadius: '20px',
            backgroundImage:
              'linear-gradient(to right,rgba(180,58,109,1) 0%, rgba(253,29,29,1) 20%, rgba(252,176,69,1) 100%)',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            margin: { xs: '0 5vw 5vw 5vw', sm: '0 5% 5% 5%' },
            zIndex: '4',
            position: 'relative',
            top: '0',
          }}>
          <Box sx={{ marginX: '2vw', marginY: '0', paddingY: '0' }}>
            <h3 className="App-profile-text">{languages[selected].home.points(points, position)}</h3>
          </Box>
        </Box>
      ) : (
        <Box></Box>
      )}
    </Fade>
  );
};

const mapDispatchToProps = (dispatch: any): Props => ({
  ...bindActionCreators(
    {
      ...rankingInteractors,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(PointsCard);
