import React, { FC } from 'react';
import Fade from '@mui/material/Fade';
import { Box } from '@mui/material';
import languages from '../../language/languages';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const PointsCard: FC = () => {
  const ranking = useSelector((state: RootState) => state.ranking);
  const { selected } = useSelector((state: RootState) => state.language);
  return (
    <Fade in={true}>
      <Box
        sx={{
          borderRadius: '20px',
          backgroundImage:
            'linear-gradient(to right,rgba(180,58,109,1) 0%, rgba(253,29,29,1) 20%, rgba(252,176,69,1) 100%)',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          margin: { xs: '0 5vw 5vw 5vw', sm: '0 5% 5% 5%' },
          zIndex: '4',
          position: 'relative',
          top: '0',
        }}>
        <Box sx={{ margin: '4vw', marginY: '0', paddingY: '2vh' }}>
          {ranking.points && ranking.position && (
            <h2 className="App-profile-text" style={{ padding: 0 }}>
              {languages[selected].ranking.points(ranking.points, ranking.position)}
            </h2>
          )}
          <h4 className="App-profile-text">{languages[selected].ranking.subtitle}</h4>
        </Box>
      </Box>
    </Fade>
  );
};

export default PointsCard;
