import React, { FC, ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { SelectChangeEvent } from '@mui/material/Select';
import { dgv, formatValue } from '../LoginScreenComponents/AddRUT';
import SwipeableViews from 'react-swipeable-views';
import { Box, TextField, Button, Stack } from '@mui/material';
import { InputAdornment, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { Stepper, Step, StepLabel } from '@mui/material';
import DateInput from '../EABCharts/DateInput';
import { useNavigate } from 'react-router-dom';
import userService from '../../services/UserServices';
import { useDispatch } from 'react-redux';
import { userActions } from '../../redux/slices/userSlice';

const steps = ['Info. Personal', 'Datos Medicos'];

const UpdateProfile: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const [email, setEmail] = useState(user.email);
  const [emailError, setEmailError] = useState('');
  const [RUT, setRUT] = useState(user.rut);
  const [RUTError, setRUTError] = useState('');
  const [name, setName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [weight, setWeight] = useState(user.weight);
  const [height, setHeight] = useState(user.height);
  const [birthday, setBirthday] = useState<string | null | undefined>(user.birthday);
  const [sex, setSex] = useState(user.sex);
  const [diabetes, setDiabetes] = useState(user.diabetes);
  const [thrombosis, setThrombosis] = useState(user.thrombosis);
  const [heartDisease, setHeartDisease] = useState(user.heart_diseases);
  const [otherDiseases, setOtherDiseases] = useState(user.other_diseases);
  const [activeStep, setActiveStep] = useState(0);
  const [phone, setPhone] = useState(user.phone);
  const [phoneError, setPhoneError] = useState('');
  const token = useSelector((state: RootState) => state.user.token);
  const changeEmail = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      setEmailError('');
    } else {
      setEmailError('Formato no válido');
    }
    setEmail(event.target.value.toLocaleLowerCase().trim());
  };
  const changeRUT = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    const formattedValue = formatValue(value);
    const checkDigit = dgv(+formattedValue.slice(0, -2));
    let validRut = '';
    if (checkDigit.toString() !== formattedValue.charAt(formattedValue.length - 1)) {
      validRut = 'El RUT entregado no es válido';
    }
    setRUT(formattedValue);
    setRUTError(validRut);
  };
  const changeName = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setName(event.target.value);
  };
  const changeLastName = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLastName(event.target.value);
  };
  const changeHeight = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      setHeight(+event.target.value);
    }
  };
  const changeWeight = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      setWeight(+event.target.value);
    }
  };
  const changeBirthday = (event: string | null) => {
    setBirthday(event);
  };
  const changeOtherDiseases = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setOtherDiseases(event.target.value);
  };
  const changeSex = (event: SelectChangeEvent) => {
    setSex(event.target.value as string);
  };
  const changePhone = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      setPhone(event.target.value);
    } else {
      setPhoneError('Error al ingresar el número');
    }
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    if (activeStep === 0) {
      navigate('/profile');
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const update = async () => {
    const response = await userService.updateUser(
      {
        email: email ? email : '',
        rut: RUT ? RUT : '',
        first_name: name ? name : '',
        last_name: lastName ? lastName : '',
        height: height ? +height : 0,
        weight: weight ? +weight : 0,
        birthday: birthday ? birthday : '',
        sex: sex ? sex : '',
        diabetes: diabetes ? diabetes : false,
        heart_diseases: heartDisease ? heartDisease : false,
        other_diseases: otherDiseases ? otherDiseases : '',
        thrombosis: thrombosis ? thrombosis : false,
        phone: phone ? phone : '',
      },
      token,
    );
    if (response.user) {
      const { user } = response;
      if (token !== null && token !== undefined) {
        dispatch(userActions.login(user));
        navigate('/profile');
      } else {
        dispatch(userActions.login(user));
        navigate('/profile');
      }
    }
  };
  return (
    <Box sx={{ maxWidth: '100vw', minWidth: '310px', display: 'inline-block', padding: '5%' }}>
      <Box sx={{ width: '100%', marginBottom: '12px' }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <SwipeableViews disabled index={activeStep}>
        <Box>
          <Button onClick={handleBack} size="small" sx={{ marginY: '2%', fontWeight: 'bold' }} variant="text">
            {'← Atras'}
          </Button>
          <TextField
            sx={{ width: '100%', marginY: '2%' }}
            label="RUT"
            variant="outlined"
            value={RUT}
            onChange={changeRUT}
            error={RUTError !== ''}
            helperText={RUTError}
          />
          <TextField
            value={email}
            onChange={changeEmail}
            sx={{ width: '100%', marginY: '2%' }}
            label="Email"
            variant="outlined"
            error={emailError !== ''}
            helperText={emailError}
            inputProps={{ inputMode: 'email' }}
          />
          <TextField
            sx={{ width: '100%', marginY: '2%' }}
            label="Telefono celular"
            variant="outlined"
            value={phone}
            inputProps={{ inputMode: 'numeric' }}
            onChange={changePhone}
            error={phoneError !== ''}
            helperText={phoneError}
          />
          <TextField
            value={name}
            onChange={changeName}
            sx={{ width: '100%', marginY: '2%' }}
            label="Nombre"
            variant="outlined"
          />
          <TextField
            value={lastName}
            onChange={changeLastName}
            sx={{ width: '100%', marginY: '2%' }}
            label="Apellido"
            variant="outlined"
          />
          <p>Fecha de nacimiento</p>
          <DateInput
            onChange={changeBirthday}
            year={birthday?.substring(0, 4)}
            month={birthday?.substring(5, 7)}
            day={birthday?.substring(8, 10)}
          />
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={12}>
            <Button
              disabled={
                RUT === '' ||
                RUTError !== '' ||
                name === '' ||
                lastName === '' ||
                phone === '' ||
                phoneError !== '' ||
                birthday === null
              }
              onClick={handleNext}
              size="large"
              sx={{ marginY: '2%', fontWeight: 'bold', borderRadius: '50px' }}
              variant="contained">
              {'Siguiente →'}
            </Button>
          </Stack>
        </Box>
        <Box>
          <Button onClick={handleBack} size="small" sx={{ marginY: '2%', fontWeight: 'bold' }} variant="text">
            {'← Atras'}
          </Button>
          <TextField
            value={weight}
            onChange={changeWeight}
            sx={{ width: '100%', marginY: '2%' }}
            label="Peso"
            variant="outlined"
            inputProps={{ inputMode: 'numeric' }}
            InputProps={{
              endAdornment: <InputAdornment position="start">kg</InputAdornment>,
            }}
          />
          <TextField
            value={height}
            onChange={changeHeight}
            sx={{ width: '100%', marginY: '2%' }}
            label="Altura"
            variant="outlined"
            inputProps={{ inputMode: 'numeric' }}
            InputProps={{
              endAdornment: <InputAdornment position="start">cm</InputAdornment>,
            }}
          />
          <FormControl sx={{ width: '100%', marginY: '2%' }}>
            <InputLabel id="Sselect-label">Sexo</InputLabel>
            <Select
              value={sex}
              onChange={changeSex}
              labelId="Sselect-label"
              id="simple-select"
              label="Sexo"
              variant="outlined"
              sx={{ width: '100%' }}>
              <MenuItem value="F">Mujer</MenuItem>
              <MenuItem value="M">Hombre</MenuItem>
              <MenuItem value="-">Prefiero no decir</MenuItem>
            </Select>
          </FormControl>
          <FormGroup>
            <p>
              Marca la casilla correspondiente si has sido <b>diagnosticado por un médico</b> con alguna de las
              siguientes enfermedades:
            </p>
            <FormControlLabel
              onChange={() => setDiabetes(diabetes === false)}
              control={<Checkbox checked={diabetes} />}
              label="Diabetes"
            />
            <FormControlLabel
              onChange={() => setThrombosis(thrombosis === false)}
              control={<Checkbox checked={thrombosis} />}
              label="Antencedentes de Trombosis"
            />
            <FormControlLabel
              onChange={() => setHeartDisease(heartDisease === false)}
              control={<Checkbox checked={heartDisease} />}
              label="Antencedentes de Enfermedades Cardiovasculares"
            />
          </FormGroup>
          <TextField
            value={otherDiseases}
            onChange={changeOtherDiseases}
            sx={{ width: '100%', marginY: '2%' }}
            label="Otras enfermedades o antecedentes"
            variant="outlined"
          />
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={12}>
            <Button
              onClick={update}
              size="large"
              sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '50px' }}
              disabled={
                RUT === '' ||
                RUTError !== '' ||
                name === '' ||
                lastName === '' ||
                birthday === null ||
                sex === '' ||
                height === null ||
                weight === null
              }
              variant="contained">
              EMPEZAR
            </Button>
          </Stack>
        </Box>
      </SwipeableViews>
    </Box>
  );
};

export default UpdateProfile;
